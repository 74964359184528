import React, { useEffect, useRef, useState } from "react";
import { ICE_TRANSPORT_POLICY } from "./connectionUtils";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { useDispatch, useSelector } from "react-redux";
import { UserRole, selectUserRole } from "redux/userRedux";
import { store } from "redux/reduxStore";
import {
  selectPeerIdWithControl,
  setPeerIdWithControl,
} from "redux/settingsRedux";
import mixpanel from "mixpanel-browser";

const addBreadcrumb = getAddBreadcrumb("webrtc.peer");

export type PeerWebRTCObject = {
  role: UserRole;

  webRTCConnection?: RTCPeerConnection;
  dataChannel?: RTCDataChannel;
  mediaStream: MediaStream;

  audioTransceiver?: RTCRtpTransceiver;
  videoTransceiver?: RTCRtpTransceiver;

  // getters to provide the state of the peer connection
  // based off the current instances state
  readonly isDataChannelOpen: boolean;
  readonly isConnected: boolean;
  readonly isConnecting: boolean;

  // perfect negotiation variables
  makingOffer?: boolean;
  ignoreOffer?: boolean;
  isSettingRemoteAnswerPending?: boolean;
};

export type Peers = {
  [key: string]: PeerWebRTCObject;
};

export const getLocalPeerId = () => {
  return mixpanel.get_distinct_id() as string;
};

const closeWebRTCConnection = (webRTCConnection?: RTCPeerConnection) => {
  if (!webRTCConnection) {
    return;
  }
  addBreadcrumb("debug", "closing peer webrtc connection");
  webRTCConnection.ondatachannel = null;
  webRTCConnection.onicecandidate = null;
  webRTCConnection.oniceconnectionstatechange = null;
  webRTCConnection.onconnectionstatechange = null;
  webRTCConnection.onicecandidateerror = null;
  webRTCConnection.onnegotiationneeded = null;
  webRTCConnection.ontrack = null;
  webRTCConnection.close();
};

export const usePeerWebRTCConnections = (
  sendMessage: (message: any) => void,
  iceServersRef: React.MutableRefObject<RTCIceServer[] | undefined>,
  isReadyToTrackEventForClient: boolean,
  trackClientSessionStart: (
    providerDataChannel: RTCDataChannel | undefined
  ) => void,
  listenForSessionStartEvent: (event: MessageEvent) => Promise<void>,
  localMediaStream: MediaStream | undefined
) => {
  // can't use useStateWithRef because we might run into a race
  // condition issue if we update the peers object twice before the useEffect runs
  const [peers, setPeers] = useState<Peers>({});
  const peersRef = useRef<Peers>(peers);

  const localMediaStreamRef = useRef<MediaStream | undefined>(localMediaStream);
  useEffect(() => {
    localMediaStreamRef.current = localMediaStream;
  }, [localMediaStream]);

  const dispatch = useDispatch();
  const localUserRole = useSelector(selectUserRole);
  const isLocalUserTherapist = localUserRole === UserRole.THERAPIST;

  const updatePeerObject = (
    peerData: Partial<PeerWebRTCObject>,
    peerId: string
  ) => {
    const newPeersObj: Peers = {
      ...peersRef.current,
      [peerId]: {
        ...peersRef.current[peerId],
        ...peerData,
        // getters for every peer object
        get isConnected() {
          return this.webRTCConnection?.connectionState === "connected";
        },
        get isConnecting() {
          return ["new", "connecting", "disconnected", "failed"].includes(
            this.webRTCConnection?.connectionState!
          );
        },
        get isDataChannelOpen() {
          return this.dataChannel?.readyState === "open";
        },
      },
    };
    peersRef.current = newPeersObj;
    setPeers(newPeersObj);
  };

  const removePeerObject = (peerId: string) => {
    const peerToBeDeleted = peersRef.current[peerId];
    closeWebRTCConnection(peerToBeDeleted.webRTCConnection);
    cleanUpPeerMediaStream(peerId);
    const newPeersObj = { ...peersRef.current };
    delete newPeersObj[peerId];
    peersRef.current = newPeersObj;
    setPeers(newPeersObj);
  };

  const sendOffer = async (peerId: string, pc: RTCPeerConnection) => {
    if (!pc) {
      logUnexpectedError(
        "peer.webRTCConnection is undefined when sending offer"
      );
      return;
    }
    addBreadcrumb("debug", "sending offer to peer with isFirstOffer: ", {
      isFirstOffer: !pc.remoteDescription,
    });
    sendMessage({
      type: "offer:peer",
      sessionDescription: pc.localDescription,
      iceServers: iceServersRef.current,
      destinationId: peerId,
      senderId: getLocalPeerId(),
      senderRole: localUserRole ?? UserRole.CLIENT,
      isFirstOffer: !pc.remoteDescription,
    });
  };

  // TODO: Replace this to not rely on message exchanges to trigger the
  // client session start event
  useEffect(() => {
    const providerPeerObject = Object.values(peers).find(
      (peer) => peer.role === UserRole.THERAPIST
    );
    const isConnectedToPeer = providerPeerObject?.isConnected;
    const isDataChannelOpen = providerPeerObject?.isDataChannelOpen;
    const isFirstClient =
      Object.values(peers).filter((peer) => peer.role === UserRole.CLIENT)
        .length === 0;
    if (
      isConnectedToPeer &&
      !isLocalUserTherapist &&
      isReadyToTrackEventForClient &&
      isDataChannelOpen &&
      isFirstClient
    ) {
      trackClientSessionStart(providerPeerObject.dataChannel);
    }
  }, [peers]);

  const setLocalMediaToPeer = (
    peerId: string,
    mediaStream: MediaStream | undefined
  ) => {
    const localAudioTrack = mediaStream?.getAudioTracks()[0];
    const localVideoTrack = mediaStream?.getVideoTracks()[0];
    const peer = peersRef.current[peerId];
    addBreadcrumb("debug", "setLocalMediaToPeer", {
      peerId,
      hasLocalMedia: !!mediaStream,
      localAudioTrack,
      localVideoTrack,
    });
    if (mediaStream) {
      if (peer.audioTransceiver) {
        peer.audioTransceiver.sender.replaceTrack(localAudioTrack ?? null);
      }
      if (peer.videoTransceiver) {
        peer.videoTransceiver.sender.replaceTrack(localVideoTrack ?? null);
      }
    }
  };

  const createPeerConnection = (
    peerId: string,
    peerRole: UserRole,
    hasOffer?: boolean
  ) => {
    addBreadcrumb("debug", "creating peer webrtc connection instance", {
      peerId,
      peerRole,
      hasOffer,
    });

    if (peerId === getLocalPeerId()) {
      logUnexpectedError(
        "Trying to create a peer connection with the same peerId"
      );
      return;
    }

    try {
      if (!iceServersRef.current) {
        logUnexpectedError(
          "iceServersRef.current is undefined while creating peer connection"
        );
        return;
      }
      // Clean up any old peer connection;
      const oldConnection = peersRef.current[peerId]?.webRTCConnection;
      if (oldConnection) {
        addBreadcrumb(
          "debug",
          "closing old connection while creating a new one"
        );
        cleanUpPeerMediaStream(peerId);
        closeWebRTCConnection(oldConnection);
        removePeerObject(peerId);
      }

      const pc = new RTCPeerConnection({
        iceServers: iceServersRef.current,
        iceTransportPolicy: ICE_TRANSPORT_POLICY,
      });

      pc.onicecandidate = (event: RTCPeerConnectionIceEvent) => {
        if (event.candidate) {
          sendMessage({
            type: "candidate:peer",
            candidate: event.candidate,
            destinationId: peerId,
            senderId: getLocalPeerId(),
          });
        }
      };
      pc.oniceconnectionstatechange = () => {
        addBreadcrumb("info", "oniceconnectionstatechange", {
          state: pc.iceConnectionState,
        });
        addBreadcrumb("debug", "peer ice connection state changed", {
          state: pc.iceConnectionState,
        });
        if (pc.iceConnectionState === "failed") {
          logUnexpectedError(
            "WebRTC peer ice connection failed, restarting ice"
          );
          pc.restartIce();
        }
      };
      pc.onconnectionstatechange = () => {
        addBreadcrumb("info", "onconnectionstatechange", {
          state: pc.connectionState,
        });
        // in case the peer reconnects and creates a new connection instance
        // that replaces the old one, and just after that the old
        // connection instance triggers the disconnected event
        if (peersRef.current[peerId]?.webRTCConnection !== pc) {
          logUnexpectedError("Got event for a connection that was replaced");
          return;
        }
        if (pc.connectionState === "closed") {
          processPeerDisconnect(peerId);
          removePeerObject(peerId);
          logUnexpectedError("WebRTC peer connection closed");
        } else if (pc.connectionState === "failed") {
          processPeerDisconnect(peerId);
          logUnexpectedError("WebRTC peer connection failed. restarting ice");
          pc.restartIce();
        } else if (pc.connectionState === "disconnected") {
          logUnexpectedError(
            "WebRTC peer connection disconnected. waiting for failed or automatic reconnection"
          );
          processPeerDisconnect(peerId);
        } else if (pc.connectionState === "connected") {
          // don't have to do anything. The re-render and
          // state getters will update everything needed

          // default first participant to have cursor control
          const activePeers = Object.entries(peersRef.current).filter(
            ([, peer]) => peer.isConnected
          );
          if (isLocalUserTherapist && activePeers.length <= 1) {
            dispatch(setPeerIdWithControl(peerId));
          }
        }
        updatePeerObject({}, peerId);
      };
      pc.onnegotiationneeded = async () => {
        addBreadcrumb("debug", "onnegotiationneeded");
        try {
          updatePeerObject({ makingOffer: true }, peerId);
          addBreadcrumb("debug", "setLocalDescription() to create offer");
          await pc.setLocalDescription();
          sendOffer(peerId, pc);
        } catch (error) {
          logUnexpectedError(error);
        } finally {
          updatePeerObject({ makingOffer: false }, peerId);
        }
      };

      // If there is an offer, the pc.setRemoteDescription() will be called
      // right after this function returns. When setting the remote description,
      // the ontrack event will be triggered, adding that tracks' transceivers.
      // If there is no offer, we will add the transceivers manually here.
      if (!hasOffer) {
        const audioTransceiver = pc.addTransceiver("audio", {
          direction: "sendrecv",
        });
        const videoTransceiver = pc.addTransceiver("video", {
          direction: "sendrecv",
        });

        updatePeerObject(
          {
            audioTransceiver,
            videoTransceiver,
          },
          peerId
        );

        // then set the local media to these transceivers
        setLocalMediaToPeer(peerId, localMediaStreamRef.current);
      }

      pc.ontrack = (ev: RTCTrackEvent) => {
        const kind = ev.track.kind;
        addBreadcrumb("info", "ontrack", { kind });

        // If we didn't crete the transceiver before, use this event's transceiver
        // and add our local media to it
        const transceiverPropName =
          kind === "audio" ? "audioTransceiver" : "videoTransceiver";
        const existingTransceiver =
          peersRef.current[peerId][transceiverPropName];
        if (!existingTransceiver) {
          addBreadcrumb("debug", "setting up transceiver on ontrack");
          ev.transceiver.direction = "sendrecv";
          updatePeerObject({ [transceiverPropName]: ev.transceiver }, peerId);
        } else {
          if (existingTransceiver !== ev.transceiver) {
            logUnexpectedError(
              "received track / transceiver, but already have one transceiver"
            );
          }
        }

        setLocalMediaToPeer(peerId, localMediaStreamRef.current);

        ev.track.addEventListener("unmute", () => {
          addBreadcrumb("info", "track.onunmute", {
            track: ev.track.id,
            kind: ev.track.kind,
          });
          let peerStream = peersRef.current[peerId].mediaStream;
          if (!peerStream) {
            peerStream = new MediaStream();
            updatePeerObject({ mediaStream: peerStream }, peerId);
          }
          // it is ok to add the same track if adding again. It won't be added twice.
          // Also, it is ok to keep previous stopped track
          peerStream.addTrack(ev.track);
          updatePeerObject({}, peerId);
        });

        ev.track.addEventListener("mute", () => {
          addBreadcrumb("info", "track.onmute", { track: ev.track.id });
          updatePeerObject({}, peerId);
        });
      };

      const newSendChannel = pc.createDataChannel("peerDataChannel", {
        negotiated: true,
        id: 0,
      });
      newSendChannel.onopen = () => {
        // updating to trigger re-renders. The isDataChannelOpen getter will provide the udpated value
        updatePeerObject({}, peerId);
        addBreadcrumb("info", "newSendChannel.onopen");
      };
      newSendChannel.onclose = () => {
        if (peersRef.current[peerId]) {
          // updating to trigger re-renders. The isDataChannelOpen getter will provide the udpated value
          updatePeerObject({}, peerId);
        }
        addBreadcrumb("info", "newSendChannel.onclose");
      };
      newSendChannel.addEventListener("message", listenForSessionStartEvent);
      updatePeerObject({ dataChannel: newSendChannel }, peerId);

      const newPeerObject = {
        webRTCConnection: pc,
        mediaStream: new MediaStream(),
        role: peerRole,
        ignoreOffer: false,
        makingOffer: false,
        isSettingRemoteAnswerPending: false,
      };
      // Updating because sometimes the object entry is already created with the datachannel
      updatePeerObject(newPeerObject, peerId);
      return pc;
    } catch (e) {
      logUnexpectedError(e);
      return;
    }
  };

  // Update the peer connections if the local media stream changes
  useEffect(() => {
    addBreadcrumb("debug", "updating media stream on peer connections");

    const activePeers = Object.entries(peersRef.current).filter(
      ([, peer]) => peer.webRTCConnection?.connectionState !== "closed"
    );
    for (const [peerId] of activePeers) {
      setLocalMediaToPeer(peerId, localMediaStream);
    }
  }, [localMediaStream]);

  const processPeerDisconnect = (peerId: string) => {
    addBreadcrumb("debug", "processing peer disconnect", { peerId });
    const peer = peersRef.current[peerId];
    const state = store.getState();
    const peerIdWithControl = selectPeerIdWithControl(state);

    if (!peer) {
      logUnexpectedError(
        new Error(
          `Trying to disconnect a peer that isn't in the peers object: ${peerId}`
        )
      );
      return;
    }

    if (isLocalUserTherapist && peerId === peerIdWithControl) {
      const activePeers = Object.entries(peersRef.current).filter(
        ([, peer]) =>
          peer.webRTCConnection?.connectionState !== "closed" &&
          peer.webRTCConnection?.connectionState !== "failed" &&
          peer.webRTCConnection?.connectionState !== "disconnected"
      );
      const firstPeerId = activePeers
        .map(([id]) => id)
        .find((id) => id !== peerId);
      if (firstPeerId) {
        dispatch(setPeerIdWithControl(firstPeerId));
      }
    }
  };

  const stopAllPeersWebRTCConnection = () => {
    addBreadcrumb("debug", "stopAllPeersWebRTCConnection");
    for (const peerId of Object.keys(peersRef.current)) {
      closeWebRTCConnection(peersRef.current[peerId].webRTCConnection);

      cleanUpPeerMediaStream(peerId);
      processPeerDisconnect(peerId);
    }
  };

  const cleanUpPeerMediaStream = (peerId: string) => {
    addBreadcrumb("debug", "cleanUpPeerMediaStream");
    const oldMediaStream = peersRef.current[peerId]?.mediaStream;
    for (const track of oldMediaStream.getTracks()) {
      track.stop();
    }
    const newMediaStream = new MediaStream();
    updatePeerObject({ mediaStream: newMediaStream }, peerId);
  };

  const handlePeerNegotiationMessage = async (message: any) => {
    try {
      addBreadcrumb("debug", "received message", {
        type: message.type,
        message: {
          ...message,
          sessionDescription: message.sessionDescription
            ? "SCRUBBED SDP"
            : message.sessionDescription,
        },
      });
      // Save the new ice servers if they are provided
      // Usually sent in the first offer, and then saved for future use
      // Currently it is only applied for new connections, but we may
      // try updating the ice servers config in the current RTCPeerConnection instance
      // on negotiationneeded or before .restartIce()
      if (message.iceServers) {
        iceServersRef.current = message.iceServers;
      }

      const description = message.sessionDescription;
      const candidate = message.candidate;

      // isFirstOffer may be true if:
      // 1. This is the first offer that we are receiving, so we should create the RTCPeerConnection
      // 2. The peer has reloaded their browser page and has a new RTCPeerConnection
      // 3. (unexpected) The peer has deleted their RTCPeerConnection and created a new one
      // For 2. we must create a new RTCPeerConnection to avoid issues with different m-lines
      if (message.isFirstOffer) {
        const previousExistingConnection =
          peersRef.current[message.senderId]?.webRTCConnection;
        addBreadcrumb(
          "debug",
          "received isFirstOffer, creating new peer connection",
          {
            previousConnectionState:
              previousExistingConnection?.connectionState,
          }
        );
        // createPeerConnection will close the previous connection if it exists
        createPeerConnection(message.senderId, message.senderRole, true);
      }

      // description is set when we receive an offer or answer
      if (description) {
        const peer = peersRef.current[message.senderId];

        // readyForOffer if we are ready to accept an offer
        // TODO: peer.webRTCConnection should always be defined and created
        // by the message.isFirstOffer condition
        const readyForOffer =
          !peer?.makingOffer &&
          ((peer.webRTCConnection?.signalingState ?? "stable") === "stable" ||
            peer.isSettingRemoteAnswerPending);

        // offerCollision if we are facing an offer collision scenario
        const offerCollision = description.type === "offer" && !readyForOffer;

        // determining which peer is the polite one
        const polite = message.senderId > getLocalPeerId();

        // The impolite peer will ignore the offer if there is an offer collision
        // At the same time, the impolite peer is sending an offer as well
        // and the polite peer will accept it and roll back their own offer
        const ignoreOffer = !polite && offerCollision;
        updatePeerObject({ ignoreOffer }, message.senderId);

        if (ignoreOffer) {
          addBreadcrumb("info", "ignoring offer", {
            polite,
            readyForOffer,
            makingOffer: peer.makingOffer,
            signalingState: peer.webRTCConnection?.signalingState,
            isSettingRemoteAnswerPending: peer.isSettingRemoteAnswerPending,
          });
          return;
        }

        // TODO: peer.webRTCConnection should always be defined and created
        // by the message.isFirstOffer condition. Check if we can remove this check
        if (!peersRef.current[message.senderId]?.webRTCConnection) {
          addBreadcrumb(
            "info",
            "creating new peer connection instance when receiving offer for the first time"
          );
          logUnexpectedError(
            "peer.webRTCConnection is undefined when receiving negotiation message"
          );
          createPeerConnection(
            message.senderId,
            message.senderRole,
            description.type === "offer"
          );
        }

        const pc = peersRef.current[message.senderId]?.webRTCConnection!;

        addBreadcrumb("debug", "setting remote description");
        // W3's perfect negotiation isSettingRemoteAnswerPending variable
        // to consider us ready to accept a new offer while setting a remote answer
        // or if an error was thrown during pc.setRemoteDescription(description)
        updatePeerObject(
          { isSettingRemoteAnswerPending: description.type == "answer" },
          message.senderId
        );
        // this setRemoteDescription should discard our current local offer if we
        // are receiving a new one (implicit rollback).
        await pc.setRemoteDescription(description);
        updatePeerObject(
          { isSettingRemoteAnswerPending: false },
          message.senderId
        );

        addBreadcrumb(
          "debug",
          "remote description set. Checking if it is an offer",
          description.type
        );
        // then, if it is an offer, we create an answer
        if (description.type === "offer") {
          addBreadcrumb(
            "debug",
            "it is an offer. calling setLocalDescription to create answer"
          );
          // the pc.setLocalDescription() without params is required to let the browser
          // decide what it should create. We expect it to be an answer
          // but it could be a pranswer, rollback, new offer, etc.
          await pc.setLocalDescription();
          addBreadcrumb("debug", "sending answer to peer");
          sendMessage({
            type: "answer:peer",
            sessionDescription: pc?.localDescription,
            destinationId: message.senderId,
            senderId: getLocalPeerId(),
          });
        }
      } else if (candidate) {
        // candidate is set when we receive an ice candidate
        try {
          const pc = peersRef.current[message.senderId]?.webRTCConnection;
          // we should always have a peer connection instance
          // TODO: throw an error if pc is undefined
          await pc?.addIceCandidate(candidate);
        } catch (error) {
          // errors are expected if we ignored an offer and we receive
          // that offer's candidates right after.
          if (!peersRef.current[message.senderId]?.ignoreOffer) {
            throw error;
          } else {
            addBreadcrumb("info", "ignoring candidate error", error);
          }
        }
      }
    } catch (error) {
      addBreadcrumb("error", "catch block while handling message");
      logUnexpectedError(error);
    }
  };

  // Called after the websocket connection to the room is ready or
  // after the websocket connection is re-created
  const peerWebRTCHandleJoinedRoom = (
    peers: { id: string; role: "therapist" | "client" }[]
  ) => {
    for (const peer of peers) {
      // if first connecting or previous one was gracefully closed
      if (
        !peersRef.current[peer.id]?.webRTCConnection ||
        peersRef.current[peer.id]?.webRTCConnection?.connectionState ===
          "closed"
      ) {
        try {
          createPeerConnection(
            peer.id,
            peer.role === "therapist" ? UserRole.THERAPIST : UserRole.CLIENT,
            false
          );
        } catch (error) {
          logUnexpectedError(error);
        }
        // if previous connection failed and is waiting to reconnect
      } else if (
        peersRef.current[peer.id]?.webRTCConnection?.connectionState ===
          "disconnected" ||
        peersRef.current[peer.id]?.webRTCConnection?.connectionState ===
          "failed"
      ) {
        addBreadcrumb("info", "restarting ice for peer with connectionState", {
          state: peersRef.current[peer.id]?.webRTCConnection?.connectionState,
        });
        peersRef.current[peer.id].webRTCConnection?.restartIce();
      }
    }
  };

  return {
    stopAllPeersWebRTCConnection,
    handlePeerNegotiationMessage,
    peerWebRTCHandleJoinedRoom,
    peers,
    peersRef,
  };
};
