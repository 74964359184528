import { useEffect, useRef } from "react";
import { useAnalyticsInfo, useTrackEvent } from "utils/metricsUtils";
import { sendEvent } from "utils/webrtcUtils";

export const useConnectionAnalytics = (
  isTherapist: boolean,
  updateAnalyticsInfoInRoomsManager: (analyticsInfo: any) => void
) => {
  const { trackEvent, isReadyToTrackEventForClient } = useTrackEvent();
  const getAnalyticsInfo = useAnalyticsInfo();
  const sessionClientIsInternalRef = useRef<boolean>();

  const sessionStartTime = useRef<number>();
  const roomStartTime = useRef<number>();

  useEffect(() => {
    if (isTherapist) {
      trackEvent("Room Open");
      roomStartTime.current = Date.now();
    }
  }, [isTherapist]);

  const trackClientSessionStart = (
    providerDataChannel: RTCDataChannel | undefined
  ) => {
    if (sessionStartTime.current !== undefined) {
      // Ignore if we've already tracked this session start (e.g. when reconnecting the same client due to internet instability)
      return;
    }
    sessionStartTime.current = Date.now();
    trackEvent("Room Session Join (Client)");
    const isInternal = localStorage.getItem("isInternal") === "true";
    sendEvent("session-start", { isInternal }, providerDataChannel);
  };

  const listenForSessionStartEvent = async (event: MessageEvent) => {
    const data = JSON.parse(event.data);
    const eventType = data.event;
    if (eventType === "session-start") {
      const peerIsInternal = data.data.isInternal;
      sessionStartTime.current = Date.now();
      trackEvent(
        "Room Session Start",
        peerIsInternal ? { ["Teleo Internal"]: true } : {}
      );
      sessionClientIsInternalRef.current = peerIsInternal;
      if (isTherapist) {
        const analyticsInfo = await getAnalyticsInfo();
        if (analyticsInfo) {
          if (peerIsInternal) {
            analyticsInfo.isInternal = true;
          }
          updateAnalyticsInfoInRoomsManager(analyticsInfo);
        }
      }
    }
  };

  const maybeTrackRoomClose = () => {
    if (isTherapist && roomStartTime.current) {
      const roomDuration = Date.now() - roomStartTime.current;
      roomStartTime.current = undefined;
      trackEvent("Room Close", {
        ["Duration - Minutes"]: roomDuration / 1000 / 60,
        ["Duration - Hours"]: roomDuration / 1000 / 60 / 60,
      });
    }
  };

  const trackSessionEnd = () => {
    if (sessionStartTime.current) {
      const sessionDuration = Date.now() - sessionStartTime.current;
      sessionStartTime.current = undefined;
      const eventName = isTherapist
        ? "Room Session End"
        : "Room Session Leave (Client)";
      trackEvent(eventName, {
        ["Duration - Minutes"]: sessionDuration / 1000 / 60,
        ["Duration - Hours"]: sessionDuration / 1000 / 60 / 60,
        ...(sessionClientIsInternalRef.current
          ? { ["Teleo Internal"]: true }
          : {}),
      });
      // Reset "isInternal" to depend only on therapist
      sessionClientIsInternalRef.current = undefined;
    }
  };

  return {
    isReadyToTrackEventForClient,
    trackClientSessionStart,
    listenForSessionStartEvent,
    maybeTrackRoomClose,
    trackSessionEnd,
  };
};
