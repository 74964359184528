import React, { useState } from "react";
import DropDown from "../DropDown/DropDown";
import styles from "./AgeDropDown.module.css";
import Slider from "./Slider/Slider";

export const MIN_AGE_FILTER = 0;
export const MAX_AGE_FILTER = 18;

export const formatAgeRange = (min: number, max: number) => {
  if (min === MIN_AGE_FILTER && max === MAX_AGE_FILTER) {
    return "Any age";
  }
  if (max === MAX_AGE_FILTER) {
    return `Ages ${min}+`;
  }
  if (min === max) {
    return `Age ${min}`;
  }
  return `Ages ${min}-${max}`;
};

type AgeDropDownProps = {
  selectedAgeMin: number;
  selectedAgeMax: number;
  setAgeMin: (value: number) => void;
  setAgeMax: (value: number) => void;
};

const AgeDropDown = ({
  selectedAgeMin,
  selectedAgeMax,
  setAgeMin,
  setAgeMax,
}: AgeDropDownProps) => {
  const [newlySelectedAgeMin, setNewlySelectedAgeMin] =
    useState<number>(selectedAgeMin);
  const [newlySelectedAgeMax, setNewlySelectedAgeMax] =
    useState<number>(selectedAgeMax);

  const cancelSelection = () => {
    setNewlySelectedAgeMin(selectedAgeMin);
    setNewlySelectedAgeMax(selectedAgeMax);
  };

  const applySelection = () => {
    setAgeMin(newlySelectedAgeMin);
    setAgeMax(newlySelectedAgeMax);
  };

  const clearSelection = () => {
    setNewlySelectedAgeMin(MIN_AGE_FILTER);
    setNewlySelectedAgeMax(MAX_AGE_FILTER);
    setAgeMin(MIN_AGE_FILTER);
    setAgeMax(MAX_AGE_FILTER);
  };

  const newAgeRangeFormatted = formatAgeRange(
    newlySelectedAgeMin,
    newlySelectedAgeMax
  );
  const value =
    selectedAgeMin === MIN_AGE_FILTER && selectedAgeMax === MAX_AGE_FILTER
      ? null
      : formatAgeRange(selectedAgeMin, selectedAgeMax);

  return (
    <DropDown
      placeholderText={"Age"}
      value={value}
      cancelSelection={cancelSelection}
      applySelection={applySelection}
      clearSelection={clearSelection}
    >
      <div className={styles.body}>
        <div className={styles.text}>{newAgeRangeFormatted}</div>
        <Slider
          min={MIN_AGE_FILTER}
          max={MAX_AGE_FILTER}
          fromValue={newlySelectedAgeMin}
          toValue={newlySelectedAgeMax}
          setFrom={setNewlySelectedAgeMin}
          setTo={setNewlySelectedAgeMax}
        />
      </div>
    </DropDown>
  );
};

export default AgeDropDown;
