import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  resetExceptMeetingID,
  resetNavigation,
} from "redux/spaceNavigationRedux";
import mixpanel from "mixpanel-browser";
import { useConnectionAnalytics } from "./useConnectionAnalytics";
import { couldBeMobileIOS } from "./connectionUtils";
import { useStateWithRef } from "hooks/useStateWithRef";
import { useRemoteWebRTCConnection } from "./useRemoteWebRTCConnection";
import {
  getLocalPeerId,
  usePeerWebRTCConnections,
} from "./usePeerWebRTCConnection";
import { useRemoteSocket } from "./useRemoteSocket";
import { useReconnectWhenOnline } from "./useReconnectWhenOnline";
import { useRoomsManager } from "./useRoomsManager";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { resetEditRoomNavigation } from "redux/editRoomNavigationRedux";
import { UserRole, selectUserRole } from "redux/userRedux";
import { setPeerIdWithControl } from "redux/settingsRedux";

type MessageType =
  | "offer:peer"
  | "answer:peer"
  | "candidate:peer"
  | "offer:remote"
  | "answer:remote"
  | "candidate:remote";

const addBreadcrumb = getAddBreadcrumb("connections");

export const useConnections = (
  meetingID: string | undefined,
  localMediaStream: MediaStream | undefined
) => {
  const iceServersRef = useRef<RTCIceServer[]>();

  const [isResettingTheRoom, setIsResettingTheRoom] = useState(false);
  const [connectionError, setConnectionError, connectionErrorRef] =
    useStateWithRef("");

  const userRole = useSelector(selectUserRole);
  const isTherapist = userRole === UserRole.THERAPIST;
  const roleString = isTherapist ? "therapist" : "client";

  const {
    setUpRoomsManagerConnection,
    closeRoomsManagerConnection,
    leaveRoomInRoomsManager,
    reconnectToRoomsManagerIfNeeded,
    signalActivity,
    updateAnalyticsInfo,
    connectionError: roomsManagerConnectionError,
    endSession: endSessionOnRoomsManager,
  } = useRoomsManager(meetingID, roleString);
  useEffect(() => {
    if (roomsManagerConnectionError) {
      setConnectionError(roomsManagerConnectionError);
    }
  }, [roomsManagerConnectionError]);

  const reconnectToRoomIfNeeded = (force?: boolean) => {
    addBreadcrumb("debug", "reconnectToRoomIfNeeded", { force });
    // TODO: remove this and rely on socket.io reconnection
    // This change would require using the auth object and change the backend
    // to mark the user as connected and disconnected accordingly.
    reconnectToRoomsManagerIfNeeded(
      onNewRoomContainer,
      onSessionEnded,
      onCloseConnections,
      !!force
    );
  };

  const {
    setUpRemoteSocketConnection,
    closeRemoteSocketConnection,
    endSession: endSessionOnRemoteSocket,
    sendMessage,
    connectionError: remoteSocketConnectionError,
  } = useRemoteSocket(roleString);
  useEffect(() => {
    if (remoteSocketConnectionError) {
      setConnectionError(remoteSocketConnectionError);
    }
  }, [remoteSocketConnectionError]);

  const {
    isReadyToTrackEventForClient,
    trackClientSessionStart,
    listenForSessionStartEvent,
    maybeTrackRoomClose,
    trackSessionEnd,
  } = useConnectionAnalytics(isTherapist, updateAnalyticsInfo);

  const onConnectedToRemote = () => {
    setIsResettingTheRoom(false);
  };

  const {
    setUpRemoteWebRTCConnection,
    closeRemoteWebRTCConnection,
    handleRemoteNegotiationMessage,
    getRemoteVideoBytesReceived,
    remoteDataChannelRef,
    remoteMediaStream,
    isConnectedToRemote,
    isConnectingToRemote,
    setIsConnectingToRemote,
  } = useRemoteWebRTCConnection(
    sendMessage,
    iceServersRef,
    onConnectedToRemote
  );

  const {
    stopAllPeersWebRTCConnection,
    handlePeerNegotiationMessage: handleMessageForPeerWebRTCConnection,
    peers,
    peersRef,
    peerWebRTCHandleJoinedRoom,
  } = usePeerWebRTCConnections(
    sendMessage,
    iceServersRef,
    isReadyToTrackEventForClient,
    trackClientSessionStart,
    listenForSessionStartEvent,
    localMediaStream
  );

  const { isOffline } = useReconnectWhenOnline(reconnectToRoomIfNeeded);

  const dispatch = useDispatch();

  // Kick out client
  const endSession = () => {
    endSessionOnRemoteSocket();
    stopAllPeersWebRTCConnection();
    endSessionOnRoomsManager();
  };

  const closeRoomConnections = () => {
    addBreadcrumb("debug", "closeRoomConnections");
    stopAllPeersWebRTCConnection();
    closeRemoteWebRTCConnection();
    closeRemoteSocketConnection();
  };

  const closeAllConnections = () => {
    addBreadcrumb("debug", "closeAllConnections");
    closeRoomConnections();
    closeRoomsManagerConnection();
  };

  // Leave the room while keeping the page open.
  // Used with Sign Out button and automatic logout.
  const leave = (message?: string) => {
    try {
      sendLeaveSignals();
      closeAllConnections();
      setConnectionError(message || "Disconnected.");
      addBreadcrumb(
        "debug",
        "Left room. Setting Disconnected connection error message."
      );
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const sendLeaveSignals = () => {
    if (connectionErrorRef.current) {
      return;
    }

    trackSessionEnd();
    maybeTrackRoomClose();

    // TODO: make this more robust to other errors to ensure that it doesn't kick out another
    // client that has joined

    leaveRoomInRoomsManager();
  };

  const onFirstInRoom = (iceServers: RTCIceServer[]) => {
    try {
      iceServersRef.current = iceServers;
      setUpRemoteWebRTCConnection();
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const onJoinedRoom = (
    iceServers: any,
    peers: { id: string; role: "therapist" | "client" }[]
  ) => {
    addBreadcrumb("info", "onJoinedRoom", { peers });
    try {
      iceServersRef.current = iceServers;
      setUpRemoteWebRTCConnection();
      // TODO: Handle peers maybe being disconnected. even the therapist
      if (!isTherapist && peers.length <= 1) {
        dispatch(setPeerIdWithControl(getLocalPeerId()));
      }
      peerWebRTCHandleJoinedRoom(peers);
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const onSessionEnded = () => {
    try {
      trackSessionEnd();
      setConnectionError("Session ended.");
      dispatch(resetExceptMeetingID());
      dispatch(resetEditRoomNavigation());
      closeAllConnections();
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const onMessage = (message: {
    type: MessageType;
    destinationId: string;
    id: string;
    sessionDescription: string;
    description: string;
    candidate: string;
    connectionId: string;
    isFirstOffer?: boolean;
  }) => {
    try {
      if (message.type.endsWith(":remote")) {
        handleRemoteNegotiationMessage(message);
      } else if (message.type.endsWith(":peer")) {
        handleMessageForPeerWebRTCConnection(message).catch(logUnexpectedError);
      }
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const connectToRoom = (containerInstance: string, containerId: string) => {
    setUpRemoteSocketConnection({
      containerInstance,
      containerId,
      onFirstInRoom,
      onJoinedRoom,
      onSessionEnded,
      onMessage,
    });
  };

  const onNewRoomContainer = (
    newContainerInstance: string,
    newContainerId: string
  ) => {
    try {
      addBreadcrumb("info", "onNewRoomContainer", {
        newContainerInstance,
        newContainerId,
      });
      setIsResettingTheRoom(true);
      trackSessionEnd();
      dispatch(resetNavigation());
      closeRoomConnections();
      setIsConnectingToRemote(true);
      connectToRoom(newContainerInstance, newContainerId);
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  const onCloseConnections = () => {
    addBreadcrumb("debug", "onCloseConnections");
    try {
      closeRoomConnections();
    } catch (err) {
      logUnexpectedError(err);
    }
  };

  useEffect(() => {
    const asyncSetUp = async () => {
      if (!meetingID) {
        return;
      }

      const {
        containerInstance,
        containerId,
        connectionError: roomsManagerConnectionError,
      } = await setUpRoomsManagerConnection(
        onNewRoomContainer,
        onSessionEnded,
        onCloseConnections
      );
      if (roomsManagerConnectionError) {
        setConnectionError(roomsManagerConnectionError);
        return;
      }

      connectToRoom(containerInstance, containerId);

      const beforeLeavePage = () => {
        try {
          mixpanel.set_config({ api_transport: "sendBeacon" }); // see https://github.com/mixpanel/mixpanel-js/issues/184
          sendLeaveSignals();
          closeAllConnections();
          return null;
        } catch (err) {
          logUnexpectedError(err);
        }
      };

      if (couldBeMobileIOS) {
        window.onpagehide = beforeLeavePage;
      } else {
        window.onbeforeunload = beforeLeavePage;
      }
    };

    asyncSetUp().catch(logUnexpectedError);

    return () => {
      try {
        sendLeaveSignals();
        closeAllConnections();
      } catch (err) {
        logUnexpectedError(err);
      }
    };
  }, []);

  return {
    remoteDataChannelRef,
    remoteMediaStream,
    peers,
    peersRef,
    localMediaStream,
    connectionError,
    isConnectedToRemote,
    isConnectingToRemote,
    isOffline,
    isResettingTheRoom,
    endSession,
    leave,
    signalActivity,
    getRemoteVideoBytesReceived,
  };
};
