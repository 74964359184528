import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import { fabric } from "utils/fabricUtils";
import { fabricTypes } from "utils/fabric-impl";
import styles from "./SpaceRoom.module.css";
import { HIT_SLOP } from "pages/Space/subpages/Whiteboard/Whiteboard";
import { Platform } from "./itemsConfig";
import { useDispatch, useSelector } from "react-redux";
import {
  selectMeetingID,
  selectRoomItems,
  selectShowRoomCustomizationActivityModal,
  setBackgroundId,
  setBackgroundSize,
  setBrowserSandboxInitialize,
  setBrowserSandboxUrl,
  setCurrentPage,
  setCurrentResourceId,
  setCurrentRoomItemId,
  setRoomItems,
  setShowRoomCustomizationActivityModal,
  setWhiteboardBackground,
  SpacePage,
} from "redux/spaceNavigationRedux";
import { sendEventToPeers } from "utils/webrtcUtils";
import clsx from "clsx";
import {
  selectCanControl,
  selectEnableResourceNameViewer,
  selectShowResourceNameOnItemHover,
} from "redux/settingsRedux";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
  selectUserRole,
  UserRole,
} from "redux/userRedux";
import {
  useGetRoomQuery,
  useUpdateItemLocationMutation,
  useUpdateThumbnailKeyMutation,
} from "generated/graphql";
import { resourceToAction } from "utils/resourceUtils";
import { useRemoteMouseOnCanvas } from "../Whiteboard/useRemoteMouseOnCanvas";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import ConnectionError from "../ConnectionError/ConnectionError";
import { logUnexpectedError } from "utils/errorUtils";
import { calculateFitDimensions } from "utils/sizingUtils";
import {
  defaultShadow,
  loadImage,
  maybeSetHoverShadow,
} from "./utils/drawingUtils";
import EditRoomTip from "./components/EditRoomTip/EditRoomTip";
import { useStateWithRef } from "hooks/useStateWithRef";
import { clearFabricCanvas, clearHTMLCanvas } from "utils/canvasUtils";
import { getBackgroundImage } from "./utils/backgroundUtils";
import RoomCustomizationActivityModal from "./components/RoomCustomizationActivityModal/RoomCustomizationActivityModal";
import {
  selectEditItemId,
  selectEditItemIsMoving,
  selectEditRoomMode,
  setEditItemId,
  setEditItemIsMoving,
  setIsDoneLoadingId,
  setIsLoadingId,
} from "redux/editRoomNavigationRedux";
import { addOrUpdateItem, setShadow } from "./utils/itemDrawingUtils";
import ItemActivityPreview from "./components/ItemActivityPreview/ItemActivityPreview";
import { useLogRoomItemEvent } from "utils/metricsUtils";
import DisabledOverlay from "pages/Space/components/DisabledOverlay/DisabledOverlay";
import { selectClientFileOpen } from "redux/clientManagementRedux";
import { useRoomItemOverlays } from "pages/Space/subpages/SpaceRoom/hooks/useRoomItemOverlays";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

type SpaceRoomProps = {
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
  setRemoteEditRoomMode: (editRoomMode: boolean) => void;
};

const SpaceRoom = ({
  peersRef,
  peers,
  setRemoteEditRoomMode,
}: SpaceRoomProps) => {
  const canvasSectionRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<fabricTypes.Canvas>();
  const [canvasIsInitialized, setCanvasIsInitialized] = useState(false);
  const remoteCursorCanvasRef = useRef<fabricTypes.Canvas>(); // for the remote cursor, so it's always in front and can't be deleted, etc
  const backgroundImageSizeRef = useRef<{ width: number; height: number }>();
  const clientCanControl = useSelector(selectCanControl);
  const showResourceNameOnItemHover = useSelector(
    selectShowResourceNameOnItemHover
  );
  const showEnableResourceNameViewer = useSelector(
    selectEnableResourceNameViewer
  );
  const enableResourceNameViewer = useSelector(selectEnableResourceNameViewer);
  const showResourceNameOnItemHoverRef = useRef(!!showResourceNameOnItemHover); // for callback
  const showEnableResourceNameViewerRef = useRef(
    !!showEnableResourceNameViewer
  ); // for callback
  const editRoomMode = useSelector(selectEditRoomMode);
  const editRoomModeRef = useRef(editRoomMode); // for callback
  const userRole = useSelector(selectUserRole);
  const userCanControl = userRole === UserRole.THERAPIST || clientCanControl;
  const showRoomCustomizationActivityModal = useSelector(
    selectShowRoomCustomizationActivityModal
  );
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const editItemIdRef = useRef<string>();
  const editItemId = useSelector(selectEditItemId);
  const editItemIsMoving = useSelector(selectEditItemIsMoving);
  const roomItems = useSelector(selectRoomItems);
  const roomItemsRef = useRef(roomItems); // for callback
  const meetingID = useSelector(selectMeetingID);
  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedToken = encodedAuthToken || encodedClientToken;
  const [hoverItemId, setHoverItemId, hoverItemIdRef] = useStateWithRef<
    string | undefined
  >(undefined);
  const [loadingPreImages, setLoadingPreImages] = useState(true);
  const [loadingImages, setLoadingImages] = useState<boolean[]>();
  const [error, setError] = useState(false);
  const dispatch = useDispatch();
  const [updateThumbnailKeyMutation] = useUpdateThumbnailKeyMutation();
  const [updateItemLocationMutation] = useUpdateItemLocationMutation();
  const [zoomLevelState, setZoomLevelState, zoomLevelStateRef] =
    useStateWithRef(1);
  const rawCanvasRef = useRef<HTMLCanvasElement>(null);
  const rawRemoteCursorCanvasRef = useRef<HTMLCanvasElement>(null);
  const logRoomItemEvent = useLogRoomItemEvent();
  const logRoomItemEventRef = useRef(logRoomItemEvent); // for mouse event callback
  useEffect(() => {
    logRoomItemEventRef.current = logRoomItemEvent;
  }, [logRoomItemEvent]);
  const mouseDownX = useRef<number>();
  const mouseDownY = useRef<number>();
  const isClientFileOpen = useSelector(selectClientFileOpen);
  const {
    fileItemIdRef,
    fileActivityStyle,
    websiteItemIdRef,
    websiteActivityStyle,
    hasAlbumItem,
    albumActivityStyle,
  } = useRoomItemOverlays(backgroundImageSizeRef);

  useEffect(() => {
    // Clear canvas contents when unmounting to work around Safari canvas caching issues
    return () => {
      for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
        clearFabricCanvas(canvas);
      }
      for (const rawCanvas of [
        rawCanvasRef.current,
        rawRemoteCursorCanvasRef.current,
      ]) {
        clearHTMLCanvas(rawCanvas);
      }
    };
  }, []);

  const { data, loading: loadingRoomItemsQuery } = useGetRoomQuery({
    variables: {
      meetingID: meetingID || "",
    },
    skip: !meetingID,
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (
      !canvasIsInitialized ||
      loadingRoomItemsQuery ||
      !data ||
      data.meeting.length === 0 ||
      !data.meeting[0].provider?.current_room
    ) {
      return;
    }
    try {
      const room = data.meeting[0].provider.current_room;
      const backgroundId = room.background_id;
      setIsBackgroundLoaded(false);
      setBackground(backgroundId).catch(logUnexpectedError);

      const roomItems = room.room_items || [];
      dispatch(setRoomItems(roomItems));
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }
  }, [data, loadingRoomItemsQuery, canvasIsInitialized]);

  useEffect(() => {
    if (!roomItems || !isBackgroundLoaded || !encodedToken) {
      return;
    }

    try {
      setLoadingPreImages(true);
      setLoadingImages(Array(roomItems.length).fill(true));

      roomItemsRef.current = roomItems;

      // Delete any items no longer included
      const currentObjects = canvasRef.current?.getObjects() || [];
      const itemIdSet = new Set(roomItems.map((item) => item.id));
      for (const object of currentObjects) {
        const obj = object as fabricTypes.Object & {
          itemId?: string;
          labelRelatedItemId?: string;
        };

        if (
          !itemIdSet.has(obj.itemId) ||
          !itemIdSet.has(obj.labelRelatedItemId)
        ) {
          canvasRef.current?.remove(object);
        }
      }

      // Add or update items
      roomItems.forEach(async (item, index) => {
        addOrUpdateItem(
          item,
          index,
          backgroundImageSizeRef,
          canvasRef,
          editItemIdRef,
          editRoomModeRef,
          showResourceNameOnItemHoverRef,
          showEnableResourceNameViewerRef,
          userRole,
          setError,
          setLoadingImages,
          dispatch
        ).catch((e) => {
          setError(true);
          logUnexpectedError(e);
        });
      });
    } catch (e) {
      setError(true);
      logUnexpectedError(e);
      setLoadingImages(Array(roomItems.length).fill(false));
    } finally {
      setLoadingPreImages(false);
    }
  }, [roomItems, isBackgroundLoaded, encodedToken]);

  useEffect(() => {
    editRoomModeRef.current = editRoomMode;
  }, [editRoomMode]);

  useEffect(() => {
    showResourceNameOnItemHoverRef.current = !!showResourceNameOnItemHover;
  }, [showResourceNameOnItemHover]);

  useEffect(() => {
    showEnableResourceNameViewerRef.current = !!enableResourceNameViewer;
  }, [enableResourceNameViewer]);

  useEffect(() => {
    const currentGroups = canvasRef.current?.getObjects("group") || [];
    for (const group of currentGroups) {
      // Only label groups will have a labelRelatedItemId
      const labelGroup = group as fabricTypes.Group & {
        labelRelatedItemId?: string;
      };
      if (labelGroup.labelRelatedItemId) {
        group.set({ visible: !!enableResourceNameViewer });
      }
    }
    canvasRef.current?.renderAll();
  }, [enableResourceNameViewer]);

  const { emitCursorMove, emitCursorOut, onReceiveMessageCallback } =
    useRemoteMouseOnCanvas(
      remoteCursorCanvasRef,
      zoomLevelState,
      zoomLevelStateRef,
      peersRef
    );

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener("message", onReceiveMessageCallback);
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallback
          );
        }
      }
    };
  }, [peers]);

  const baseOnMouseOut = () => {
    emitCursorOut();
  };

  const calculateCanvasDimensions = () => {
    const parentHeight = canvasSectionRef.current?.clientHeight || 0;
    const parentWidth = canvasSectionRef.current?.clientWidth || 0;
    const rawWidth = backgroundImageSizeRef.current?.width || 0;
    const rawHeight = backgroundImageSizeRef.current?.height || 0;
    const { fitHeight, fitWidth, ratio } = calculateFitDimensions(
      parentHeight,
      parentWidth,
      rawHeight,
      rawWidth,
      null
    );
    setZoomLevelState(ratio);
    return {
      canvasHeight: fitHeight,
      canvasWidth: fitWidth,
      zoomLevel: ratio,
    };
  };

  function updateSize() {
    const { canvasHeight, canvasWidth, zoomLevel } =
      calculateCanvasDimensions();
    // Avoid setting to 0 because this causes the canvas to be blank when it is made bigger
    // again later.
    if (!canvasHeight || !canvasWidth || !zoomLevel) {
      return;
    }
    for (const canvas of [canvasRef.current, remoteCursorCanvasRef.current]) {
      if (!canvas) {
        continue;
      }
      canvas.setHeight(canvasHeight);
      canvas.setWidth(canvasWidth);
      canvas.setZoom(zoomLevel);
    }
  }

  useLayoutEffect(() => {
    window.addEventListener("resize", updateSize);
    return () => window.removeEventListener("resize", updateSize);
  }, []);

  useEffect(() => {
    editItemIdRef.current = editItemId;
    // Set shadows
    for (const object of canvasRef.current?.getObjects() || []) {
      setShadow(object, editItemIdRef, editRoomModeRef);
    }
    canvasRef.current?.renderAll();
  }, [editItemId]);

  useEffect(() => {
    // Set shadows and update the cursor for the poster
    for (const object of canvasRef.current?.getObjects() || []) {
      setShadow(object, editItemIdRef, editRoomModeRef);
      // @ts-ignore
      if (object.isPoster) {
        object.set({ hoverCursor: editRoomMode ? "pointer" : "default" });
      }
      // @ts-ignore
      if (object.itemId) {
        // Make the items movable (or unmovable, if closing edit room mode)
        object.selectable = editRoomMode;
      }
    }
    canvasRef.current?.renderAll();
  }, [editRoomMode]);

  const updateThumbnailKey = async (
    resourceId: string,
    thumbnailKey: string
  ) => {
    await updateThumbnailKeyMutation({
      variables: {
        resourceId,
        thumbnailKey,
      },
    });
  };

  const setBackground = async (backgroundId: string) => {
    dispatch(setBackgroundId(backgroundId));
    const backgroundImageSrc = getBackgroundImage(backgroundId);
    const backgroundImage = await loadImage(backgroundImageSrc);
    backgroundImage.set({ opacity: 0.7 });
    const backgroundSize = {
      height: backgroundImage.height || 0,
      width: backgroundImage.width || 0,
    };
    dispatch(setBackgroundSize(backgroundSize));
    backgroundImageSizeRef.current = backgroundSize;
    updateSize();
    canvasRef.current?.setBackgroundImage(backgroundImage, () => {
      setIsBackgroundLoaded(true);
    });
  };

  const initializeCanvas = () => {
    try {
      if (!canvasRef.current) {
        // Fabric will create a wrapper around the html canvas element with the id 'canv'
        canvasRef.current = new fabric.Canvas("room-canv", {
          backgroundColor: "white",
          selection: false,
          targetFindTolerance: HIT_SLOP * 2,
          preserveObjectStacking: true,
        });

        canvasRef.current?.on("mouse:over", async (event) => {
          // @ts-ignore
          const itemId = event.target?.itemId;
          // @ts-ignore
          const isPoster = event.target?.isPoster;
          if (event.target && itemId && canvasRef.current) {
            setHoverItemId(itemId);
            maybeSetHoverShadow(
              event.target,
              itemId,
              isPoster,
              editRoomModeRef.current,
              editItemIdRef.current
            );
            canvasRef.current.renderAll();
          }
        });
        canvasRef.current?.on("mouse:out", async (event) => {
          // @ts-ignore
          const itemId = event.target?.itemId;
          // @ts-ignore
          const isPoster = event.target?.isPoster;
          if (event.target && itemId) {
            if (itemId === hoverItemIdRef.current) {
              setHoverItemId(undefined);
            }
            if (editRoomModeRef.current && itemId === editItemIdRef.current) {
              return;
            }
            // Don't show a shadow on the poster unless editing the room
            if (!editRoomModeRef.current && isPoster) {
              return;
            }
            const scale = event.target.scaleX || 1;
            const shadow = defaultShadow(scale);
            event.target.set({ shadow });
            canvasRef.current?.renderAll();
          }
        });
        canvasRef.current?.on("mouse:down", (event) => {
          mouseDownX.current = event.e.pageX;
          mouseDownY.current = event.e.pageY;
        });
        canvasRef.current?.on("mouse:up", async (event) => {
          // Detect if drag or click
          const upX = event.e.pageX;
          const upY = event.e.pageY;
          const isClick =
            upX === mouseDownX.current && upY === mouseDownY.current;
          mouseDownX.current = undefined;
          mouseDownY.current = undefined;
          if (editRoomModeRef.current) {
            // @ts-ignore
            const itemId = event.target?.itemId;
            if (isClick) {
              // Click
              if (event.target && itemId) {
                if (itemId !== editItemIdRef.current) {
                  dispatch(setEditItemId(itemId));
                }
              } else {
                dispatch(setEditItemId(null));
              }
            } else {
              // Drag
              if (event.target && itemId && itemId !== editItemIdRef.current) {
                dispatch(setEditItemId(itemId));
              }
              if (!event.target || !itemId) {
                return;
              }
              dispatch(setEditItemIsMoving(false));
              const errorMessage =
                "Sorry, there was a problem moving the item. Please try again later.";
              if (!backgroundImageSizeRef.current) {
                logUnexpectedError(
                  "No backgroundImageSizeRef.current when dragging object"
                );
                alert(errorMessage);
                return;
              }
              const loadingKey = `CHANGE_ITEM_LOCATION_${Date.now()}`;
              dispatch(setIsLoadingId(loadingKey));
              const newTop = event.target?.top;
              const newLeft = event.target?.left;
              const { errors } = await updateItemLocationMutation({
                variables: {
                  itemId: itemId,
                  rx: (newLeft || 0) / backgroundImageSizeRef.current.width,
                  ry: (newTop || 0) / backgroundImageSizeRef.current.height,
                },
              });
              if (errors) {
                logUnexpectedError(errors);
                alert(errorMessage);
              } else {
                const item = roomItemsRef.current?.find(
                  (item) => item.id === itemId
                );
                if (item) {
                  logRoomItemEventRef
                    .current({
                      action: "MOVE",
                      iconId: item.icon_id,
                      resourceId: item.resource.id,
                    })
                    .catch(logUnexpectedError);
                }
              }
              dispatch(setIsDoneLoadingId(loadingKey));
            }
            return;
          }

          // @ts-ignore
          const itemId = event.target?.itemId;
          const roomItem = roomItemsRef.current?.find(
            (item) => item.id === itemId
          );
          const resource = roomItem?.resource;
          if (!resource) {
            return;
          }
          const onClick = resourceToAction(resource);
          const resourceId = resource.id;
          if (userRole === UserRole.THERAPIST) {
            if (itemId === fileItemIdRef.current) {
              // @ts-ignore
              window.pendo.onGuideAdvanced(3);
            }
            if (itemId === websiteItemIdRef.current) {
              // @ts-ignore
              window.pendo.onGuideAdvanced(5);
            }
          }
          if (onClick?.type === Platform.WHITEBOARD) {
            if (onClick.backgroundImageKey) {
              dispatch(setWhiteboardBackground(onClick.backgroundImageKey));
            }
            dispatch(setCurrentPage(SpacePage.WHITEBOARD));
            dispatch(setCurrentResourceId(resourceId));
            dispatch(setCurrentRoomItemId(itemId));
            sendEventToPeers(peersRef.current, "navigate", {
              currentPage: SpacePage.WHITEBOARD,
              whiteboardBackground: onClick.backgroundImageKey,
              resourceId,
              roomItemId: itemId,
            });
          } else if (onClick?.type === Platform.BROWSER_SANDBOX) {
            dispatch(setBrowserSandboxUrl(onClick.url));
            dispatch(setBrowserSandboxInitialize(true));
            dispatch(setCurrentPage(SpacePage.BROWSER_SANDBOX));
            dispatch(setCurrentResourceId(resourceId));
            dispatch(setCurrentRoomItemId(itemId));
            sendEventToPeers(peersRef.current, "navigate", {
              currentPage: SpacePage.BROWSER_SANDBOX,
              browserSandboxUrl: onClick.url,
              resourceId,
              roomItemId: itemId,
            });
          } else if (onClick?.type === Platform.MEDIA_PLAYER) {
            // TODO: add new state variable for media player
            dispatch(setBrowserSandboxUrl(onClick.url));
            dispatch(setCurrentPage(SpacePage.MEDIA_PLAYER));
            dispatch(setCurrentResourceId(resourceId));
            dispatch(setCurrentRoomItemId(itemId));
            sendEventToPeers(peersRef.current, "navigate", {
              currentPage: SpacePage.MEDIA_PLAYER,
              browserSandboxUrl: onClick.url,
              resourceId,
              roomItemId: itemId,
            });
          } else if (onClick?.type === Platform.ROOM_CUSTOMIZATION) {
            dispatch(setShowRoomCustomizationActivityModal(true));
            dispatch(setCurrentResourceId(resourceId));
            dispatch(setCurrentRoomItemId(itemId));
            sendEventToPeers(peersRef.current, "navigate", {
              currentPage: SpacePage.ROOM,
              showRoomCustomizationActivityModal: true,
              resourceId,
              roomItemId: itemId,
            });
          } else if (onClick?.type === Platform.ALBUM) {
            dispatch(setCurrentPage(SpacePage.ALBUM));
            dispatch(setCurrentResourceId(resourceId));
            dispatch(setCurrentRoomItemId(itemId));
            sendEventToPeers(peersRef.current, "navigate", {
              currentPage: SpacePage.ALBUM,
              resourceId,
              roomItemId: itemId,
            });
          }
        });
      }
      setCanvasIsInitialized(true);
      if (!remoteCursorCanvasRef.current) {
        remoteCursorCanvasRef.current = new fabric.Canvas(
          "room-remoteCursorCanv",
          {
            backgroundColor: undefined,
          }
        );
      }
    } catch (e) {
      logUnexpectedError(e);
      setError(true);
    }

    const mouseMoveListener = (event: MouseEvent) => {
      if (editRoomModeRef.current) {
        return;
      }
      const pointer = canvasRef.current?.getPointer(event);
      if (pointer) {
        emitCursorMove(pointer);
      }
    };

    window.addEventListener("mousemove", mouseMoveListener);
    return () => {
      window.removeEventListener("mousemove", mouseMoveListener);
    };
  };

  useEffect(initializeCanvas, []);

  useEffect(() => {
    updateSize();
  }, [editRoomMode, isClientFileOpen]);

  const noCurrentRoom =
    !loadingRoomItemsQuery && !data?.meeting[0]?.provider?.current_room;
  const loadingRoomImages =
    (loadingPreImages || (loadingImages && loadingImages.includes(true))) &&
    !noCurrentRoom;

  // Order the images correctly once they have all been added to the canvas
  useEffect(() => {
    if (!loadingRoomImages) {
      const currentItems =
        // @ts-ignore
        canvasRef.current?.getObjects().filter((o) => o.z !== undefined) || [];
      // @ts-ignore
      currentItems.sort((a, b) => b.z - a.z);
      for (const object of currentItems) {
        // Send to back rather than front so that other objects, like the activity
        // preview pop-ups, stay in front.
        object?.sendToBack();
      }
    }
  }, [loadingRoomImages]);

  const noRoomMessage =
    userRole === UserRole.THERAPIST
      ? "No room is currently opened. Please open a room."
      : "No room is currently opened. Please wait for your provider to open a room.";

  return (
    <div className={styles.space} data-testid="space-room">
      <div
        className={clsx(styles.canvasAndSideBarContainer, {
          [styles.noControlCanvasSection]: !userCanControl,
          [styles.editing]: editRoomMode,
        })}
      >
        <div className={styles.canvasAndTopBarContainer}>
          {editRoomMode && !noCurrentRoom && userRole === UserRole.THERAPIST ? (
            <EditRoomTip setRemoteEditRoomMode={setRemoteEditRoomMode} />
          ) : null}
          <div
            className={clsx(styles.canvasSection, {
              [styles.noControlCanvasSection]: !userCanControl,
            })}
            ref={canvasSectionRef}
          >
            <div
              data-testid="room-canvas-container"
              className={clsx(styles.canvasContainer, {
                [styles.noControlCanvasContainer]: !userCanControl,
              })}
              onPointerLeave={baseOnMouseOut}
            >
              <canvas id="room-canv" ref={rawCanvasRef} />
              {showRoomCustomizationActivityModal && !editRoomMode ? (
                <RoomCustomizationActivityModal
                  peersRef={peersRef}
                  peers={peers}
                  roomWidth={canvasRef.current?.width}
                />
              ) : null}
              <div className={styles.remoteTmpCanvas}>
                <canvas
                  id="room-remoteCursorCanv"
                  ref={rawRemoteCursorCanvasRef}
                />
              </div>
              {isBackgroundLoaded ? (
                <div
                  id={"feelingsChartOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={fileActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded ? (
                <div
                  id={"pencilCupOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={websiteActivityStyle}
                />
              ) : null}
              {isBackgroundLoaded && hasAlbumItem ? (
                <div
                  id={"albumOverlay"}
                  className={styles.feelingsChartOverlayForTour}
                  style={albumActivityStyle}
                />
              ) : null}
              {editRoomMode && editItemId && !editItemIsMoving ? (
                <ItemActivityPreview
                  itemId={editItemId}
                  updateThumbnailKey={updateThumbnailKey}
                  canvasRef={canvasRef}
                />
              ) : null}
              {editRoomMode &&
              hoverItemId &&
              hoverItemId !== editItemId &&
              !editItemIsMoving ? (
                <ItemActivityPreview
                  itemId={hoverItemId}
                  updateThumbnailKey={updateThumbnailKey}
                  canvasRef={canvasRef}
                />
              ) : null}
            </div>
            {noCurrentRoom ? (
              <div className={styles.noRoomMessage}>{noRoomMessage}</div>
            ) : null}
            {loadingRoomImages ? (
              <div
                className={clsx(styles.loadingContainer, {
                  [styles.semitransparent]: isBackgroundLoaded,
                })}
              >
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {loadingRoomItemsQuery ? (
              <div className={styles.loadingContainer}>
                {" "}
                <LoadingAnimation />{" "}
              </div>
            ) : null}
            {error ? (
              <ConnectionError
                errorMessage={
                  "There was an error loading the room. Please try again later."
                }
                loading={false}
                showInviteLink={false}
              />
            ) : null}
          </div>
        </div>
      </div>
      {editRoomMode && userRole === UserRole.CLIENT ? (
        <DisabledOverlay message={"Hold tight! This room is being edited."} />
      ) : null}
    </div>
  );
};

export default SpaceRoom;
