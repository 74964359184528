import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { OnProgressProps } from "react-player/base";
import ReactPlayer from "react-player/lazy";
import clsx from "clsx";

import { selectCanControl } from "redux/settingsRedux";
import { selectUserRole, UserRole } from "redux/userRedux";
import ActivityNavigationHeader from "pages/Space/components/ActivityNavigationHeader/ActivityNavigationHeader";
import styles from "./MediaPlayer.module.css";
import { useRemoteMediaPlayer } from "../Whiteboard/useRemoteMediaPlayer";
import { logUnexpectedError } from "utils/errorUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

const HEADER_MESSAGE =
  "Only your client can start the video when they are on mobile.";

type MediaPlayerProps = {
  url?: string;
  peersRef: React.MutableRefObject<Peers>;
  peers: Peers;
};

const MediaPlayer = ({ url, peersRef, peers }: MediaPlayerProps) => {
  // keep track of the previous two progress values to determine if the user is seeking
  const secondPreviousProgressRef = useRef<number>(0);
  const previousProgressRef = useRef<number>(0);
  const [hasStarted, setHasStarted] = useState(false);
  const playerRef = useRef<ReactPlayer>(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const clientCanControl = useSelector(selectCanControl);
  const userRole = useSelector(selectUserRole);
  const userCanControl = userRole === UserRole.THERAPIST || clientCanControl;

  // Temporary workaround for Soundcloud
  const enablePeriodicSync = useMemo(
    () => url?.includes("soundcloud.com"),
    [url]
  );

  const {
    emitIsPlayingChange,
    emitSeekTo,
    onReceiveMessageCallback: onReceiveMessageCallbackRemoteMediaPlayer,
  } = useRemoteMediaPlayer(playerRef, setIsPlaying);

  const handleStart = () => {
    setHasStarted(true);
  };

  const handlePlay = () => {
    if (!isPlaying) {
      const currentTime = playerRef.current?.getCurrentTime();
      if (currentTime !== undefined) {
        // avoid sending a floating point number (mainly for less than 1 second)
        const currentTimeInWholeSeconds = Math.floor(currentTime);
        emitSeekTo(currentTimeInWholeSeconds, peersRef);
      }
      setIsPlaying(true);
      emitIsPlayingChange(true, peersRef);
    }
  };

  const handlePause = () => {
    if (isPlaying) {
      setIsPlaying(false);
      emitIsPlayingChange(false, peersRef);
    }
  };

  const handleProgress = (progress: OnProgressProps) => {
    if (
      Math.abs(progress.playedSeconds - previousProgressRef.current) > 2 &&
      previousProgressRef.current - secondPreviousProgressRef.current < 2
    ) {
      emitSeekTo(progress.playedSeconds, peersRef);
    }
    secondPreviousProgressRef.current = previousProgressRef.current;
    previousProgressRef.current = progress.playedSeconds;
  };

  useEffect(() => {
    const peerDataChannels = Object.values(peers).map(
      (peer) => peer.dataChannel
    );
    for (const peerDataChannel of peerDataChannels) {
      if (peerDataChannel) {
        peerDataChannel.addEventListener(
          "message",
          onReceiveMessageCallbackRemoteMediaPlayer
        );
      }
    }

    return () => {
      for (const peerDataChannel of peerDataChannels) {
        if (peerDataChannel) {
          peerDataChannel.removeEventListener(
            "message",
            onReceiveMessageCallbackRemoteMediaPlayer
          );
        }
      }
    };
  }, [peers]);

  return (
    <div data-testid="media-player" className={styles.container}>
      <div
        className={clsx(styles.navOuterContainer, {
          [styles.outerNoControl]: !userCanControl,
        })}
      >
        <div
          className={clsx(styles.navInnerContainer, {
            [styles.innerNoControl]: !userCanControl,
          })}
        >
          <ActivityNavigationHeader
            peersRef={peersRef}
            isExternal={true}
            message={
              userRole === UserRole.THERAPIST ? HEADER_MESSAGE : undefined
            }
          />
        </div>
      </div>
      <div
        className={clsx(styles.playerOuterContainer, {
          [styles.outerNoControl]: !userCanControl && hasStarted,
        })}
      >
        <div
          className={clsx(styles.playerContainer, {
            [styles.innerNoControl]: !userCanControl && hasStarted,
          })}
        >
          <ReactPlayer
            url={url}
            playing={isPlaying}
            controls={true}
            height={"100%"}
            width={"100%"}
            ref={playerRef}
            onStart={handleStart}
            onPlay={handlePlay}
            onPause={handlePause}
            onProgress={enablePeriodicSync ? handleProgress : undefined}
            onError={logUnexpectedError}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaPlayer;
