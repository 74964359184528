import { readLargeEvent, sendLargeEventToPeers } from "utils/webrtcUtils";
import ReactPlayer from "react-player/lazy";
import { logUnexpectedError } from "utils/errorUtils";
import { Peers } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export const useRemoteMediaPlayer = (
  playerRef: React.MutableRefObject<ReactPlayer | null>,
  setIsPlaying: React.Dispatch<React.SetStateAction<boolean>>
) => {
  const emitIsPlayingChange = (
    isPlaying: boolean,
    peersRef: React.MutableRefObject<Peers>
  ) => {
    sendLargeEventToPeers(peersRef.current, "is-playing-change", { isPlaying });
  };

  const emitSeekTo = (
    time: number,
    peersRef: React.MutableRefObject<Peers>
  ) => {
    sendLargeEventToPeers(peersRef.current, "seek-to", { time });
  };

  const onReceiveMessageCallback = async (event: MessageEvent) => {
    const data = await readLargeEvent(event);
    if (!data) {
      return;
    }
    const eventType = data.event;
    switch (eventType) {
      case "is-playing-change":
        const isPlaying: boolean = data.data.isPlaying;
        setIsPlaying(isPlaying);
        break;
      case "seek-to":
        if (!playerRef.current) {
          logUnexpectedError("Player ref is not set during seek-to event");
          return;
        }
        const time: number = data.data.time;
        playerRef.current.seekTo(time);
        break;
    }
  };

  return {
    emitIsPlayingChange,
    emitSeekTo,
    onReceiveMessageCallback,
  };
};
