import React from "react";

import styles from "./AdminPage.module.css";
import { signOutServer, useSignIn } from "utils/signInUtils";
import { useAutomaticLogout } from "pages/Space/hooks/useAutomaticLogout";
import { useSelector } from "react-redux";
import { selectDecodedAuthToken } from "redux/userRedux";
import DeploymentSection from "./DeploymentSection";
import ResourceUsageSection from "./ResourceUsageSection";
import ContainerDebugSection from "./ContainerDebugSection";
import UserPilotLengthSection from "./UserPilotLengthSection";
import BackgroundUsageSection from "./BackgroundUsageSection";
import SearchUsageSection from "./SearchUsageSection";
import CustomResourceLookupSection from "pages/AdminPage/CustomResourceLookupSection";

const AdminPage = () => {
  const redirectPath = "/admin";
  const signOut = async () => {
    await signOutServer();
    if (process.env.REACT_APP_COGNITO_LOGOUT) {
      location.href = process.env.REACT_APP_COGNITO_LOGOUT + redirectPath;
    }
  };
  useSignIn(redirectPath, signOut);
  useAutomaticLogout(signOut);
  const decodedToken = useSelector(selectDecodedAuthToken);

  // @ts-ignore
  if (!decodedToken?.groups?.includes("admin")) {
    return <div className={styles.container}>Not permitted</div>;
  }

  return (
    <div className={styles.container}>
      <button onClick={signOut}>Sign Out</button>
      <DeploymentSection />
      <CustomResourceLookupSection />
      <UserPilotLengthSection />
      <ContainerDebugSection />
      <BackgroundUsageSection />
      <ResourceUsageSection />
      <SearchUsageSection />
    </div>
  );
};

export default AdminPage;
