import { getIconConfig, IconConfig } from "../itemsConfig";
import { fabricTypes } from "utils/fabric-impl";
import { defaultShadow, loadImage } from "./drawingUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { fabric } from "utils/fabricUtils";
import { truncateResourceName } from "utils/resourceUtils";
import { RoomItemFragment } from "generated/graphql";
import { addPosterToRoom, DEFAULT_POSTER_SHADOW } from "./posterUtil";
import { setEditItemIsMoving } from "redux/editRoomNavigationRedux";
import { Dispatch } from "@reduxjs/toolkit";
import { COLORS } from "teleoConstants";
import { UserRole } from "redux/userRedux";

const MAX_RESOURCE_NAME_LENGTH = 22;

export const setShadow = (
  object: fabricTypes.Object,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  editRoomModeRef: React.MutableRefObject<boolean | undefined>
) => {
  const scale = object.scaleX || 1;
  // @ts-ignore
  const itemId = object.itemId;
  // @ts-ignore
  const isPoster = object.isPoster;
  if (itemId) {
    let shadow = defaultShadow(scale);
    if (itemId === editItemIdRef.current) {
      shadow = `rgba(0,0,0,1) ${10 / scale}px ${10 / scale}px ${5 / scale}px`;
    } else if (isPoster && !editRoomModeRef.current) {
      shadow = DEFAULT_POSTER_SHADOW;
    }
    object.set({ shadow });
  }
};

export const doesItemImageMatch = (
  matchingItem: fabricTypes.Image,
  src: any,
  iconFileKey: string | undefined
) => {
  // @ts-ignore
  if (matchingItem.isPoster) {
    // @ts-ignore
    return matchingItem.iconFileKey === iconFileKey;
  } else {
    return matchingItem.getSrc() === src;
  }
};

export const getPositionInCanvas = (
  top: number,
  left: number,
  width: number,
  height: number,
  canvasWidth: number,
  canvasHeight: number
) => {
  if (!canvasHeight || !canvasWidth) {
    return {
      newTop: top,
      newLeft: left,
    };
  }
  const bottom = top + height;
  const right = left + width;

  const AMOUNT_IN_CANVAS = 200;
  let newTop = top;
  const amountToShowVertical = Math.min(AMOUNT_IN_CANVAS, height);
  if (top < 0 && bottom - AMOUNT_IN_CANVAS < 0) {
    newTop = amountToShowVertical - height;
  } else if (
    bottom > canvasHeight &&
    top + amountToShowVertical > canvasHeight
  ) {
    newTop = canvasHeight - amountToShowVertical;
  }
  let newLeft = left;
  const amountToShowHorizontal = Math.min(AMOUNT_IN_CANVAS, width);
  if (left < 0 && right - amountToShowHorizontal < 0) {
    newLeft = amountToShowHorizontal - width;
  } else if (
    right > canvasWidth &&
    left + amountToShowHorizontal > canvasWidth
  ) {
    newLeft = canvasWidth - amountToShowHorizontal;
  }

  return {
    newTop,
    newLeft,
  };
};

// If the object is partially outside the canvas, moves the object so it is mainly inbounds.
const setPositionInCanvas = (obj: fabricTypes.Object) => {
  const canvasWidth = obj.canvas?.getWidth();
  const canvasHeight = obj.canvas?.getHeight();
  const zoom = obj.canvas?.getZoom();
  const top = obj.top;
  const left = obj.left;
  const height = obj.getScaledHeight();
  const width = obj.getScaledWidth();
  if (
    !canvasWidth ||
    !canvasHeight ||
    !zoom ||
    top === undefined ||
    left === undefined
  ) {
    return;
  }
  const scaledCanvasWidth = canvasWidth / zoom;
  const scaledCanvasHeight = canvasHeight / zoom;
  const { newTop, newLeft } = getPositionInCanvas(
    top,
    left,
    width,
    height,
    scaledCanvasWidth,
    scaledCanvasHeight
  );
  if (newTop !== obj.top) {
    obj.top = newTop;
  }
  if (newLeft !== obj.left) {
    obj.left = newLeft;
  }
};

export const updateItemFields = async (
  itemImage: fabricTypes.Image,
  item: RoomItemFragment,
  scale: number | undefined,
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  editRoomModeRef: React.MutableRefObject<boolean | undefined>,
  dispatch: Dispatch
) => {
  const isPoster = item.icon_id === "POSTER";
  itemImage.set({
    top: item.ry * (backgroundImageSize.current?.height || 0),
    left: item.rx * (backgroundImageSize.current?.width || 0),
    scaleX: scale,
    scaleY: scale,
    srcFromAttribute: true,
    hoverCursor: isPoster && !editRoomModeRef.current ? "default" : "pointer",
    filters: isPoster
      ? [new fabric.Image.filters.Blur({ blur: 0.05 })]
      : undefined,
    selectable: !!editRoomModeRef.current,
  });

  itemImage.set({
    // @ts-ignore
    itemId: item.id,
    iconId: item.icon_id,
    z: item.z,
    isPoster,
    iconFileKey: item.icon_file?.key,
  });
  setShadow(itemImage, editItemIdRef, editRoomModeRef);
  itemImage.on("moving", () => {
    dispatch(setEditItemIsMoving(true));
    // Keep at least part of the object in the bounds of the canvas.
    setPositionInCanvas(itemImage);
  });
};

const getHorizontalCenteringDistance = (
  imageWidth: number,
  labelWidth: number
) => (imageWidth - labelWidth) / 2;

const addResourceLabel = (
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  item: RoomItemFragment,
  oImg: fabricTypes.Image,
  resourceName: string,
  editRoomModeRef: React.MutableRefObject<boolean | undefined>,
  showResourceNameOnItemHoverRef: React.MutableRefObject<boolean>,
  showEnableResourceNameViewerRef: React.MutableRefObject<boolean>
) => {
  const label = new fabric.Text(
    truncateResourceName(resourceName, MAX_RESOURCE_NAME_LENGTH),
    {
      selectable: false,
      evented: false,
      fontFamily: "Catamaran, sans-serif",
      fontSize: 16,
      fill: COLORS.DARK,
      originX: "center",
      originY: "center",
    }
  ) as fabricTypes.Text;

  const labelContainer = new fabric.Rect({
    fill: COLORS.LIGHT,
    width: label.getScaledWidth() + 20,
    height: 26,
    selectable: false,
    evented: false,
    opacity: 0.8,
    shadow: {
      color: "rgba(0,0,0,0.3)",
      blur: 4,
    },
    originX: "center",
    originY: "center",
    ry: 4,
    rx: 4,
  }) as fabricTypes.Rect;

  const horizontalCenteringDistance = getHorizontalCenteringDistance(
    oImg.getScaledWidth(),
    labelContainer.getScaledWidth()
  );
  const VERTICAL_OFFSET = 14;

  const group = new fabric.Group([labelContainer, label], {
    labelRelatedItemId: item.id,
    top: (oImg.top ?? 0) + oImg.getScaledHeight() - VERTICAL_OFFSET,
    left: (oImg.left ?? 0) + horizontalCenteringDistance,
    selectable: false,
    evented: false,
    visible: showEnableResourceNameViewerRef.current,
  }) as fabricTypes.Group & { labelRelatedItemId?: string };

  oImg.on("moving", () => {
    const movingHorizontalCenteringDistance = getHorizontalCenteringDistance(
      oImg.getScaledWidth(),
      labelContainer.getScaledWidth()
    );
    group.set({
      top: (oImg.top ?? 0) + oImg.getScaledHeight() - VERTICAL_OFFSET,
      left: (oImg.left ?? 0) + movingHorizontalCenteringDistance,
    });
  });

  const enableShowResourceNameOnHoverEvents = () => {
    return (
      showResourceNameOnItemHoverRef.current &&
      !editRoomModeRef.current &&
      !showEnableResourceNameViewerRef.current
    );
  };

  oImg.on("mouseover", () => {
    if (enableShowResourceNameOnHoverEvents()) {
      group.set({ visible: true });
      canvasRef.current?.renderAll();
    }
  });

  oImg.on("mouseout", () => {
    if (enableShowResourceNameOnHoverEvents()) {
      group.set({ visible: false });
      canvasRef.current?.renderAll();
    }
  });

  canvasRef.current?.add(group);
};

export const addItem = async (
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  item: RoomItemFragment,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  editRoomModeRef: React.MutableRefObject<boolean | undefined>,
  showResourceNameOnItemHoverRef: React.MutableRefObject<boolean>,
  showEnableResourceNameViewerRef: React.MutableRefObject<boolean>,
  userRole: UserRole | null,
  setDoneLoading: () => void,
  setError: () => void,
  iconConfig: IconConfig | undefined,
  dispatch: Dispatch
) => {
  const isPoster = item.icon_id === "POSTER";
  if (isPoster) {
    addPosterToRoom(
      backgroundImageSize,
      canvasRef,
      item,
      editItemIdRef,
      editRoomModeRef,
      setDoneLoading,
      setError,
      dispatch
    ).catch((e) => {
      logUnexpectedError(e);
      setError();
    });
    return;
  }

  try {
    const oImg = await loadImage(iconConfig?.src);
    await updateItemFields(
      oImg,
      item,
      iconConfig?.scale,
      backgroundImageSize,
      editItemIdRef,
      editRoomModeRef,
      dispatch
    );
    // Only add if it hasn't already been added while waiting for image loading
    // (can happen in certain race conditions)
    const currentObjects = canvasRef.current?.getObjects() || [];
    // @ts-ignore
    const matchingItems: fabricTypes.Image[] = currentObjects.filter(
      // @ts-ignore
      (object) => object.itemId === item.id
    );
    if (matchingItems.length === 0) {
      canvasRef.current?.add(oImg);

      if (userRole === UserRole.THERAPIST && item.resource.name) {
        addResourceLabel(
          canvasRef,
          item,
          oImg,
          item.resource.name,
          editRoomModeRef,
          showResourceNameOnItemHoverRef,
          showEnableResourceNameViewerRef
        );
      }

      canvasRef.current?.renderAll();
    }
  } catch (e) {
    logUnexpectedError(e);
    setError();
  } finally {
    setDoneLoading();
  }
};

export const addOrUpdateItem = async (
  item: RoomItemFragment,
  index: number,
  backgroundImageSize: React.MutableRefObject<
    { width: number; height: number } | undefined
  >,
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>,
  editItemIdRef: React.MutableRefObject<string | undefined>,
  editRoomModeRef: React.MutableRefObject<boolean | undefined>,
  showResourceNameOnItemHoverRef: React.MutableRefObject<boolean>,
  showEnableResourceNameViewerRef: React.MutableRefObject<boolean>,
  userRole: UserRole | null,
  setError: (value: boolean) => void,
  setLoadingImages: React.Dispatch<React.SetStateAction<boolean[] | undefined>>,
  dispatch: Dispatch
) => {
  const iconConfig = getIconConfig(item.icon_id);

  const setDoneLoadingImage =
    (itemIndex: number) => (previousLoadingImages: boolean[] | undefined) => {
      if (previousLoadingImages) {
        const newLoadingImages = [...previousLoadingImages];
        newLoadingImages[itemIndex] = false;
        return newLoadingImages;
      }
      return undefined;
    };
  const setDoneLoadingThisImage = () =>
    setLoadingImages(setDoneLoadingImage(index));

  const currentObjects = canvasRef.current?.getObjects() || [];
  // @ts-ignore
  const matchingItems: fabricTypes.Image[] = currentObjects.filter(
    // @ts-ignore
    (object) => object.itemId === item.id
  );
  let foundMatchingImageItem = false;
  for (const matchingItem of matchingItems) {
    // Remove any label group associated with this item
    currentObjects.forEach((object) => {
      // @ts-ignore
      if (object.labelRelatedItemId === item.id) {
        canvasRef.current?.remove(object);
      }
    });
    const itemImageMatches = doesItemImageMatch(
      matchingItem,
      iconConfig?.src,
      item.icon_file?.key
    );
    if (itemImageMatches) {
      if (foundMatchingImageItem) {
        // Delete existing image if there is more than one that matches.
        // Generally there should only be one of each icon total, but there are race
        // conditions where there might incorrectly end up being more than one poster added, and this
        // recovers (sloppily) from that case.
        canvasRef.current?.remove(matchingItem);
        continue;
      }
      foundMatchingImageItem = true;

      await updateItemFields(
        matchingItem,
        item,
        iconConfig?.scale,
        backgroundImageSize,
        editItemIdRef,
        editRoomModeRef,
        dispatch
      );
      matchingItem.setCoords(); // Otherwise sometimes the items are not selectable, see http://fabricjs.com/fabric-gotchas

      if (userRole === UserRole.THERAPIST && item.resource.name) {
        addResourceLabel(
          canvasRef,
          item,
          matchingItem,
          item.resource.name,
          editRoomModeRef,
          showResourceNameOnItemHoverRef,
          showEnableResourceNameViewerRef
        );
      }

      setDoneLoadingThisImage();
    } else {
      canvasRef.current?.remove(matchingItem);
    }
  }
  if (!foundMatchingImageItem) {
    addItem(
      backgroundImageSize,
      canvasRef,
      item,
      editItemIdRef,
      editRoomModeRef,
      showResourceNameOnItemHoverRef,
      showEnableResourceNameViewerRef,
      userRole,
      setDoneLoadingThisImage,
      () => setError(true),
      iconConfig,
      dispatch
    ).catch((e) => {
      logUnexpectedError(e);
      setError(true);
    });
  }

  canvasRef.current?.renderAll();
};
