import React, {
  MouseEventHandler,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import styles from "./DropDown.module.css";
import expandIcon from "assets/icons/expand_more.svg";
import clsx from "clsx";
import Button from "components/Button/Button";
import xIcon from "assets/icons/x.svg";

type DropDownProps = {
  placeholderText: string;
  value: string | null;
  cancelSelection: () => void;
  applySelection: () => void;
  clearSelection: () => void;
  rightAlign?: boolean;
  children: React.ReactNode;
};

const DropDown = ({
  placeholderText,
  value,
  cancelSelection,
  applySelection,
  clearSelection,
  rightAlign,
  children,
}: DropDownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [scrollbarIsVisible, setScrollbarIsVisible] = useState(false);
  const bodyRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (isOpen) {
      const scrollbarIsNowVisible =
        (bodyRef.current?.scrollHeight || 0) >
        (bodyRef.current?.clientHeight || 0);
      setScrollbarIsVisible(scrollbarIsNowVisible);
    }
  }, [isOpen]);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
    if (isOpen) {
      cancelSelection();
    }
  };

  const cancel = () => {
    cancelSelection();
    setIsOpen(false);
  };

  const apply = () => {
    applySelection();
    setIsOpen(false);
  };

  const clear: MouseEventHandler<HTMLDivElement> = (event) => {
    clearSelection();
    event.stopPropagation();
  };

  return (
    <>
      {isOpen ? <div className={styles.overlay} onClick={toggleOpen} /> : null}
      <div
        className={clsx(styles.container, {
          [styles.activeContainer]: isOpen,
          [styles.containerWithValue]: value,
        })}
      >
        <div
          className={clsx(styles.topPart, {
            [styles.topPartWithoutValue]: !value,
            [styles.activeTopPart]: isOpen,
          })}
          onClick={toggleOpen}
        >
          <div
            className={clsx(styles.leftPart, {
              [styles.leftPartWithValue]: value,
            })}
          >
            {value ? (
              <div className={clsx(styles.text, styles.textWithValue)}>
                {value}
              </div>
            ) : (
              <div className={styles.text}>{placeholderText}</div>
            )}
          </div>
          <div
            className={clsx(styles.rightButton, {
              [styles.closeButton]: value,
            })}
            onClick={value ? clear : undefined}
          >
            {value ? (
              <img className={styles.xIcon} src={xIcon} />
            ) : (
              <img
                className={clsx(styles.expandIcon, {
                  [styles.openExpandIcon]: isOpen,
                })}
                src={expandIcon}
              />
            )}
          </div>
        </div>
        {isOpen ? (
          <div
            className={clsx(styles.dropDown, {
              [styles.rightAlign]: rightAlign,
            })}
          >
            <div
              ref={bodyRef}
              className={clsx(styles.body, {
                [styles.bodyWithScrollbar]: scrollbarIsVisible,
              })}
            >
              {children}
            </div>
            <div className={styles.actionRow}>
              <Button
                className={clsx(styles.button, styles.cancelButton)}
                onClick={cancel}
              >
                Cancel
              </Button>
              <Button className={styles.button} onClick={apply}>
                Apply
              </Button>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default DropDown;
