import { createSlice } from "@reduxjs/toolkit";

export interface Client {
  canonical_id: string;
  name: string;
}

type ClientSelectionStage =
  | "none"
  | "clientLoadSelect"
  | "clientAdmissionSelect"
  | "newClient"
  | "confirmName";

export type SnapshotDetail = {
  id: string;
  created_at: string;
  fileUrl: string;
  fileKey: string;
  resource: string;
  clientName: string;
  sharedWithClient: boolean;
};

export interface ClientManagementState {
  currentClient?: Client;
  clientSelectionStage: ClientSelectionStage;
  incomingClient?: { name: string; id: string };
  clientSearchText: string;
  selectingClient?: Client;

  newClientReturnStage?: ClientSelectionStage;

  loadingClientForSnapshot: boolean;
  waitingRoomAlertClients: string[];
  clientHasJoinedRoom: boolean;

  clientFileOpen: boolean;
  showConfirmClientDelete: boolean;
  snapshotDetail?: SnapshotDetail;
  showConfirmSnapshotDelete: boolean;
}

export interface RootState {
  clientManagement: ClientManagementState;
}

const INITIAL_STATE = {
  clientSelectionStage: "none",
  admitPeerAs: "differentClient",
  clientSearchText: "",
  loadingClientForSnapshot: false,
  waitingRoomAlertClients: [],
  clientHasJoinedRoom: false,
  clientFileOpen: false,
  showConfirmClientDelete: false,
  showConfirmSnapshotDelete: false,
} as ClientManagementState;

export const clientManagementSlice = createSlice({
  name: "clientManagement",
  initialState: INITIAL_STATE,
  reducers: {
    setCurrentClient: (
      state,
      { payload }: { payload: ClientManagementState["currentClient"] }
    ) => {
      // close client file sidebar if client is disconnected
      if (payload === undefined) {
        state.clientFileOpen = false;
      }
      state.currentClient = payload;
    },
    openLoadModal: (state, { payload }: { payload: boolean | undefined }) => {
      state.clientSelectionStage = "clientLoadSelect";
      state.selectingClient = state.currentClient;
      state.loadingClientForSnapshot = !!payload;
    },
    loadClient: (state, { payload }: { payload: Client }) => {
      state.currentClient = payload;
      state.selectingClient = undefined;
      state.clientSelectionStage = "none";
      state.clientSearchText = "";
    },
    cancelLoading: (state) => {
      state.selectingClient = undefined;
      state.clientSelectionStage = "none";
    },
    triggerClientAdmission: (
      state,
      { payload }: { payload: ClientManagementState["incomingClient"] }
    ) => {
      state.incomingClient = payload;
      state.clientSelectionStage = "clientAdmissionSelect";
      state.selectingClient = state.currentClient;
    },
    admitAdditionalParticipant: (state) => {
      state.clientSelectionStage = "none";
      state.clientSearchText = "";
    },
    // Not used anymore
    confirmClientSwitch: (state) => {
      state.clientSelectionStage = "clientAdmissionSelect";
    },
    // Not used anymore
    cancelClientSwitch: (state) => {
      state.clientSelectionStage = "none";
      state.incomingClient = undefined;
    },
    openNewClient: (state) => {
      state.newClientReturnStage = state.clientSelectionStage;
      state.clientSelectionStage = "newClient";
    },
    cancelNewClient: (state) => {
      if (state.newClientReturnStage) {
        state.clientSelectionStage = state.newClientReturnStage;
        state.newClientReturnStage = undefined;
      }
    },
    clientCreated: (state, { payload }: { payload: Client }) => {
      if (state.newClientReturnStage) {
        state.clientSelectionStage = state.newClientReturnStage;
        state.newClientReturnStage = undefined;
      }
      state.selectingClient = payload;
    },
    setClientSearchText: (state, { payload }: { payload: string }) => {
      state.clientSearchText = payload;
    },
    setSelectingClient: (
      state,
      { payload }: { payload: Client | undefined }
    ) => {
      state.selectingClient = payload;
    },
    admitClient: (state, { payload }: { payload: Client }) => {
      state.currentClient = payload;
      state.clientSelectionStage = "none";
      state.clientSearchText = "";
    },
    cancelAdmitClient: (state) => {
      state.selectingClient = undefined;
      state.clientSearchText = "";
      state.incomingClient = undefined;
      state.clientSelectionStage = "none";
    },
    openNameConfirmationModal: (state) => {
      state.clientSelectionStage = "confirmName";
    },
    cancelNameConfirmationModal: (state) => {
      state.clientSelectionStage = "clientAdmissionSelect";
    },
    pushWaitingRoomAlertClient: (state, value) => {
      state.waitingRoomAlertClients.push(value.payload);
    },
    pushWaitingRoomAlertClients: (state, value) => {
      state.waitingRoomAlertClients.push(...value.payload);
    },
    removeWaitingRoomAlertClient: (state, value) => {
      state.waitingRoomAlertClients = state.waitingRoomAlertClients.filter(
        (id) => id !== value.payload
      );
    },
    setClientHasJoinedRoom: (
      state,
      { payload }: { payload: ClientManagementState["clientHasJoinedRoom"] }
    ) => {
      state.clientHasJoinedRoom = payload;
    },
    toggleClientFileOpen: (state) => {
      state.clientFileOpen = !state.clientFileOpen;
    },
    toggleShowConfirmClientDelete: (state) => {
      state.showConfirmClientDelete = !state.showConfirmClientDelete;
    },
    setSnapshotDetail: (
      state,
      { payload }: { payload: ClientManagementState["snapshotDetail"] }
    ) => {
      state.snapshotDetail = payload;
    },
    toggleShowConfirmSnapshotDelete: (state) => {
      state.showConfirmSnapshotDelete = !state.showConfirmSnapshotDelete;
    },
  },
});

export const {
  setCurrentClient,
  openLoadModal,
  loadClient,
  cancelLoading,
  triggerClientAdmission,
  confirmClientSwitch,
  cancelClientSwitch,
  admitAdditionalParticipant,
  openNewClient,
  cancelNewClient,
  clientCreated,
  setClientSearchText,
  setSelectingClient,
  admitClient,
  cancelAdmitClient,
  openNameConfirmationModal,
  cancelNameConfirmationModal,
  pushWaitingRoomAlertClient,
  pushWaitingRoomAlertClients,
  removeWaitingRoomAlertClient,
  setClientHasJoinedRoom,
  toggleClientFileOpen,
  toggleShowConfirmClientDelete,
  setSnapshotDetail,
  toggleShowConfirmSnapshotDelete,
} = clientManagementSlice.actions;

export const selectCurrentClient = (state: RootState) =>
  state.clientManagement.currentClient;
export const selectClientSelectionStage = (state: RootState) =>
  state.clientManagement.clientSelectionStage;
export const selectIncomingClient = (state: RootState) =>
  state.clientManagement.incomingClient;
export const selectClientSearchText = (state: RootState) =>
  state.clientManagement.clientSearchText;
export const selectSelectingClient = (state: RootState) =>
  state.clientManagement.selectingClient;
export const selectLoadingClientForSnapshot = (state: RootState) =>
  state.clientManagement.loadingClientForSnapshot;
export const selectWaitingRoomAlertClients = (state: RootState) =>
  state.clientManagement.waitingRoomAlertClients;
export const selectClientHasJoinedRoom = (state: RootState) =>
  state.clientManagement.clientHasJoinedRoom;
export const selectClientFileOpen = (state: RootState) =>
  state.clientManagement.clientFileOpen;
export const selectShowConfirmClientDelete = (state: RootState) =>
  state.clientManagement.showConfirmClientDelete;
export const selectSnapshotDetail = (state: RootState) =>
  state.clientManagement.snapshotDetail;
export const selectShowConfirmSnapshotDelete = (state: RootState) =>
  state.clientManagement.showConfirmSnapshotDelete;

export default clientManagementSlice.reducer;
