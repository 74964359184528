import React, { useLayoutEffect, useRef, useState } from "react";

import styles from "./TextButton.module.css";
import clsx from "clsx";
import { COLORS } from "teleoConstants";
import { createPortal } from "react-dom";
import { ReactComponent as ArrowIcon } from "assets/icons/expand_more.svg";
import { TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER } from "./WhiteboardControls";

export type TextSize = "S" | "M" | "L";

interface TextButtonProps {
  isSelected: boolean;
  buttonStyle: {
    height: number;
    width: number;
    borderRadius: number;
    margin: number;
    fontSize: number;
    borderWidth: number;
  };
  onClick: () => void;
  textSizeRef: React.MutableRefObject<TextSize>;
}

const TextButton = ({
  isSelected,
  buttonStyle,
  onClick,
  textSizeRef,
}: TextButtonProps) => {
  const [size, setSize] = useState<TextSize>("M");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [menuStyles, setMenuStyles] = useState<React.CSSProperties>({});
  const buttonRef = useRef<HTMLButtonElement>(null);

  const handleClick = () => {
    if (isSelected) {
      setIsMenuOpen(!isMenuOpen);
    } else {
      onClick();
    }
  };

  const handleArrowButtonClick = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleSizeMenuClick = (size: TextSize) => {
    if (!isSelected) {
      onClick();
    }
    setSize(size);
    textSizeRef.current = size;
    setIsMenuOpen(false);
  };

  useLayoutEffect(() => {
    const buttonRect = buttonRef.current?.getBoundingClientRect();
    if (buttonRect) {
      const buttonWidthWithBorder =
        buttonStyle.width +
        2 * Math.max(buttonStyle.borderWidth, buttonStyle.margin);
      const containerPadding =
        TOOLBAR_HORIZONTAL_PADDING_MULTIPLIER * buttonWidthWithBorder;
      const menuWidth = buttonWidthWithBorder + 2 * containerPadding;
      setMenuStyles({
        top: buttonRect.top - buttonStyle.margin,
        left:
          buttonRect.left -
          buttonStyle.margin -
          menuWidth -
          containerPadding +
          5,
        width: menuWidth,
        boxSizing: "border-box",
        padding: containerPadding,
      });
    }
  }, [isMenuOpen, buttonStyle]);

  const menuButtonStyle = {
    ...buttonStyle,
    backgroundColor: COLORS.GRAY,
    borderWidth: Math.max(buttonStyle.borderWidth, buttonStyle.margin),
    margin: 0,
  };

  return (
    <div
      className={clsx(styles.textButtonContainer, {
        [styles.isSelected]: isSelected,
      })}
    >
      <button
        id={"textButton"}
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        ref={buttonRef}
        onClick={handleClick}
      >
        T
      </button>
      <button
        className={clsx(styles.button, styles.subButton, {
          [styles.isSelected]: isSelected,
        })}
        style={{
          ...buttonStyle,
          backgroundColor: COLORS.GRAY,
          width: buttonStyle.width / 2.5,
          height: buttonStyle.height / 2.5,
          fontSize: buttonStyle.fontSize / 2.5,
          borderWidth: buttonStyle.borderWidth / 1.5,
          margin: buttonStyle.margin / 1.5,
          bottom: (-1 * buttonStyle.borderWidth) / 1.5,
          right: (-1 * buttonStyle.borderWidth) / 1.5,
        }}
        onClick={handleClick}
      >
        {size}
      </button>
      <div
        className={styles.arrowContainer}
        style={{
          left: -1 * ((menuStyles.padding ?? 0) as number),
          width: ((menuStyles.padding ?? 0) as number) * 1.2,
        }}
        onClick={handleArrowButtonClick}
      >
        <ArrowIcon
          className={clsx(styles.arrowIcon, {
            [styles.isMenuOpen]: isMenuOpen,
          })}
        />
      </div>

      {isMenuOpen &&
        createPortal(
          <>
            <div
              className={styles.overlay}
              onClick={() => setIsMenuOpen(false)}
            />
            <div className={styles.menu} style={menuStyles}>
              <div
                className={clsx(styles.button, styles.menuOption, {
                  [styles.isSelected]: size === "S",
                })}
                style={{
                  ...menuButtonStyle,
                  fontSize: menuButtonStyle.fontSize * 0.6,
                  marginBottom: menuButtonStyle.margin + 10,
                }}
                onClick={() => handleSizeMenuClick("S")}
              >
                S
              </div>
              <div
                className={clsx(styles.button, styles.menuOption, {
                  [styles.isSelected]: size === "M",
                })}
                style={{
                  ...menuButtonStyle,
                  fontSize: menuButtonStyle.fontSize * 0.8,
                  marginBottom: menuButtonStyle.margin + 10,
                }}
                onClick={() => handleSizeMenuClick("M")}
              >
                M
              </div>
              <div
                className={clsx(styles.button, styles.menuOption, {
                  [styles.isSelected]: size === "L",
                })}
                style={{
                  ...menuButtonStyle,
                  fontSize: menuButtonStyle.fontSize * 1.3,
                }}
                onClick={() => handleSizeMenuClick("L")}
              >
                L
              </div>
            </div>
          </>,
          document.body
        )}
    </div>
  );
};

export default TextButton;
