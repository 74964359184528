// A video stream component for either a local or remote stream

import React, { useEffect, useLayoutEffect, useRef } from "react";

import styles from "./Video.module.css";

type VideoProps = {
  stream: MediaStream | undefined;
  muted?: boolean;
  setRawVideoDimensions: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
};

export const Video = ({ stream, muted, setRawVideoDimensions }: VideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  const updateRawDimensions = () => {
    setRawVideoDimensions({
      width: videoRef.current?.videoWidth || 0,
      height: videoRef.current?.videoHeight || 0,
    });
  };

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
      updateRawDimensions();
    }

    return () => {
      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }
    };
  }, [stream]);

  useLayoutEffect(() => {
    updateRawDimensions();
    videoRef.current?.addEventListener("resize", updateRawDimensions);

    return () => {
      videoRef.current?.removeEventListener("resize", updateRawDimensions);
    };
  }, [videoRef, setRawVideoDimensions]);

  return (
    <video
      className={styles.video}
      ref={videoRef}
      autoPlay
      playsInline
      disablePictureInPicture
      muted={muted}
    />
  );
};
