import React, { useEffect, useState } from "react";

import styles from "./ItemActivityThumbnail.module.css";
import fileIcon from "assets/icons/file.svg";
import websiteIcon from "assets/icons/world.svg";
import clsx from "clsx";
import { getFileUrl } from "utils/fileUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { maybeCreateAndSetThumbnailFile } from "utils/thumbnailUtils";
import { RoomItemResourceFragment } from "generated/graphql";
import { ResourceType, THUMBNAIL_ERROR_KEY } from "../../utils/drawingUtils";

type ItemActivityThumbnailProps = {
  resource: RoomItemResourceFragment | undefined;
  updateThumbnailKey: (resourceId: string, thumbnailKey: string) => void;
};

const ItemActivityThumbnail = ({
  resource,
  updateThumbnailKey,
}: ItemActivityThumbnailProps) => {
  const [src, setSrc] = useState<string>();

  const resourceType = resource?.url ? ResourceType.WEBSITE : ResourceType.FILE;
  const thumbnailFileKey = resource?.thumbnail_file_key;

  const useFallbackThumbnail =
    thumbnailFileKey === THUMBNAIL_ERROR_KEY || !thumbnailFileKey;

  useEffect(() => {
    // If it's a custom file without a thumbnail, try to fetch the thumbnail.
    if (resource && !thumbnailFileKey) {
      maybeCreateAndSetThumbnailFile(resource, updateThumbnailKey).catch(
        logUnexpectedError
      );
    }
  }, [resource]);

  useEffect(() => {
    const asyncCode = async () => {
      if (useFallbackThumbnail) {
        setSrc(resourceType === ResourceType.FILE ? fileIcon : websiteIcon);
      } else {
        setSrc(await getFileUrl(thumbnailFileKey));
      }
    };
    asyncCode().catch(logUnexpectedError);
  }, [useFallbackThumbnail, thumbnailFileKey]);

  return (
    <div
      className={clsx(styles.container, {
        [styles.fallbackThumbnailContainer]: useFallbackThumbnail,
      })}
    >
      <img
        className={clsx(styles.img, {
          [styles.fallbackThumbnailIcon]: useFallbackThumbnail,
        })}
        src={src}
      />
    </div>
  );
};

export default ItemActivityThumbnail;
