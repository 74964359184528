import React, { useEffect, useState } from "react";

import styles from "./ClientSelectionModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  admitClient,
  cancelAdmitClient,
  cancelLoading,
  loadClient,
  openNameConfirmationModal,
  selectClientFileOpen,
  selectClientHasJoinedRoom,
  selectClientSelectionStage,
  selectCurrentClient,
  selectIncomingClient,
  selectLoadingClientForSnapshot,
  selectSelectingClient,
  setSelectingClient,
  toggleClientFileOpen,
} from "redux/clientManagementRedux";
import {
  useGetClientsQuery,
  useSetCurrentClientMutation,
} from "generated/graphql";
import { selectUserId } from "redux/userRedux";
import ClientSelectionList from "../ClientSelectionList/ClientSelectionList";
import { useAdmitClient } from "utils/waitingRoomUtils";
import { triggerSnapshotTask } from "redux/spaceNavigationRedux";
import { createFuseInstance } from "utils/fuseUtils";
import { logUnexpectedError } from "utils/errorUtils";
import { useLogClientManagementEvent } from "utils/metricsUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";

type ClientSelectionModalProps = {};

const ClientSelectionModal = ({}: ClientSelectionModalProps) => {
  const dispatch = useDispatch();

  const userId = useSelector(selectUserId);
  const clientsQuery = useGetClientsQuery({
    variables: { providerId: userId },
    fetchPolicy: "cache-first",
  });

  const isClientFileOpen = useSelector(selectClientFileOpen);

  const clientSelectionStage = useSelector(selectClientSelectionStage);
  const incomingClient = useSelector(selectIncomingClient);
  const previouslySelectedClient = useSelector(selectCurrentClient);
  const selectingClient = useSelector(selectSelectingClient);
  const admitClientOnServer = useAdmitClient();
  const providerId = useSelector(selectUserId);
  const [setCurrentClient] = useSetCurrentClientMutation();

  const ehrSystem = useSelector(selectSessionEHRSystem);

  const logClientManagementEvent = useLogClientManagementEvent();
  const [clientSelectedFrom, setClientSelectedFrom] = useState<
    "fromFilteredList" | "fromFullList" | undefined
  >(undefined);
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const isLoadingClientForSnapshot = useSelector(
    selectLoadingClientForSnapshot
  );

  const closeModal = () => {
    if (clientSelectionStage === "clientAdmissionSelect") {
      dispatch(cancelAdmitClient());
    } else if (clientSelectionStage === "clientLoadSelect") {
      dispatch(cancelLoading());
    }
  };

  const clientNameMatches = (nameA: string, nameB: string) => {
    const fuse = createFuseInstance([nameA]);
    const fuseResult = fuse.search(nameB);
    if (fuseResult.length !== 1) {
      return false;
    } else {
      return true;
    }
  };

  const saveCurrentClientOnDB = async (
    clientId: string,
    providerId: string
  ) => {
    const result = await setCurrentClient({
      variables: {
        clientId: clientId,
        providerId: providerId,
      },
    });
    if (
      result.errors ||
      !result.data?.update_user_by_pk?.current_client_canonical_id
    ) {
      alert("There was an error loading the client. Please try again.");
    }
  };

  const handleSubmit = async () => {
    // should not be called without a client selected
    if (!selectingClient) {
      logUnexpectedError(
        new Error("Unexpectedly trying to select a client without one")
      );
      alert("You must select a client.");
      return;
    }

    let action:
      | "CLIENT_SELECTED_FROM_SEARCH_RESULTS"
      | "CLIENT_SELECTED_FROM_FULL_LIST"
      | "CLIENT_SELECTED_AUTOMATICALLY";
    switch (clientSelectedFrom) {
      case "fromFilteredList":
        action = "CLIENT_SELECTED_FROM_SEARCH_RESULTS";
        break;
      case "fromFullList":
        action = "CLIENT_SELECTED_FROM_FULL_LIST";
        break;
      default:
        action = "CLIENT_SELECTED_AUTOMATICALLY";
        break;
    }
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: action,
      clientCanonicalId: selectingClient.canonical_id,
      duringSession: clientHasJoinedRoom,
    });

    if (clientSelectionStage === "clientAdmissionSelect") {
      if (
        !clientNameMatches(incomingClient?.name ?? "", selectingClient.name)
      ) {
        dispatch(openNameConfirmationModal());
        // not awaiting to let it run in the background
        logClientManagementEvent({
          action: "NAME_CONFIRMATION_SHOWN",
          clientName: selectingClient.name,
          incomingName: incomingClient?.name ?? "",
        });
      } else {
        await admitClientOnServer({});
        await saveCurrentClientOnDB(selectingClient.canonical_id, providerId);
        // not awaiting to let it run in the background
        logClientManagementEvent({
          action: "CLIENT_ADMITTED",
          clientCanonicalId: selectingClient.canonical_id,
          previousClientCanonicalId: previouslySelectedClient?.canonical_id,
          duringSession: clientHasJoinedRoom,
        });
        dispatch(admitClient(selectingClient));
      }
    } else if (clientSelectionStage === "clientLoadSelect") {
      await saveCurrentClientOnDB(selectingClient.canonical_id, providerId);
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_LOADED",
        clientCanonicalId: selectingClient.canonical_id,
        previousClientCanonicalId: previouslySelectedClient?.canonical_id,
        duringSession: clientHasJoinedRoom,
      });
      dispatch(loadClient(selectingClient));
      if (isLoadingClientForSnapshot) {
        dispatch(triggerSnapshotTask());
      } else if (!isClientFileOpen) {
        dispatch(toggleClientFileOpen());
      }
    }
  };

  const clientList = clientsQuery.data?.client
    ? [...clientsQuery.data?.client].sort((a, b) =>
        a.name.localeCompare(b.name)
      )
    : [];

  // Only run if there is no client being selected,
  // and if there is an incomingClient, and the clientList is loaded
  // then, the best match is selected.
  useEffect(() => {
    if (
      !selectingClient &&
      incomingClient &&
      clientList &&
      clientList.length > 0
    ) {
      const fuseList = createFuseInstance(clientList);
      const fuseResults = fuseList.search(incomingClient?.name);
      // as results are sorted, the first result is the best match
      if (fuseResults.length > 0) {
        dispatch(setSelectingClient(fuseResults[0].item));
      }
    }
  }, [incomingClient, clientList]);

  return (
    <Modal closeModal={closeModal}>
      {clientSelectionStage === "clientAdmissionSelect" ? (
        <>
          <div className={styles.heading}>Admit Client</div>
          <div className={styles.subheading}>
            {!!incomingClient?.name &&
              `Select the client for "${incomingClient.name}".`}
          </div>
        </>
      ) : (
        <>
          <div className={styles.heading}>Load Client</div>
          {isLoadingClientForSnapshot && (
            <div className={styles.subheading}>
              Select the client for which to save this snapshot.
            </div>
          )}
        </>
      )}
      <ClientSelectionList
        clientList={clientList ?? []}
        value={selectingClient}
        onChange={(client, from) => {
          dispatch(setSelectingClient(client));
          setClientSelectedFrom(from);
        }}
        initialFilter={previouslySelectedClient?.name ?? incomingClient?.name}
        showNewClientButton={!ehrSystem}
      />
      <div className={styles.buttonRow}>
        <Button className={styles.cancelButton} onClick={closeModal}>
          Cancel
        </Button>

        <Button onClick={handleSubmit} disabled={!selectingClient}>
          {clientSelectionStage === "clientAdmissionSelect"
            ? "Admit"
            : isLoadingClientForSnapshot
            ? "Load & Save"
            : "Load"}
        </Button>
      </div>
    </Modal>
  );
};

export default ClientSelectionModal;
