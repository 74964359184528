export const COLORS = {
  WHITE: "#FFFFFF",
  LIGHT: "#FBFBFA",
  GRAY: "#F0F0F0",
  MID_GRAY: "#E5E5E5",
  DARK_300: "#7f8c8a",
  DARK_400: "#556663",
  DARK: "#2A403C",
  YELLOW_100: "#fdf7db",
  YELLOW_200: "#fcefb6",
  YELLOW: "#F7D749",
  GREEN_100: "#cde4e3",
  GREEN_200: "#9ac9c6",
  GREEN: "#037971",
  RED_100: "#f4dedb",
  RED_200: "#eabdb7",
  RED: "#CA594A",
  BLUE_100: "#dfe8f0",
  BLUE_200: "#c0d0e2",
  BLUE: "#618AB6",
};
