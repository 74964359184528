import React, { useEffect, useRef, useState } from "react";

import styles from "./ItemActivityPreview.module.css";
import { useSelector } from "react-redux";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import ItemActivityThumbnail from "../ItemActivityThumbnail/ItemActivityThumbnail";
import { fabricTypes } from "utils/fabric-impl";
import ItemButtons from "../ItemButtons/ItemButtons";
import { selectEditItemId } from "redux/editRoomNavigationRedux";
import clsx from "clsx";

const PREVIEW_SIZE = 230;
const PREVIEW_STROKE_WIDTH = 4;
const FONT_SIZE = 28;

const LINE_WIDTH = 170;

const getNumLines = (
  div: HTMLDivElement,
  text: string,
  end: number,
  ellipsis: string
) => {
  div.innerText = text.substring(0, end) + ellipsis;
  return div.offsetHeight / 10;
};

const getNumCharactersInLineSingleWord = (
  text: string,
  div: HTMLDivElement,
  ellipsis: string
) => {
  if (getNumLines(div, text, text.length, ellipsis) === 1) {
    return text.length;
  }

  let ind = Math.ceil(text.length / 2);
  let step = Math.ceil(text.length / 4);
  while (true) {
    if (getNumLines(div, text, ind, ellipsis) === 1) {
      ind += step;
    } else {
      ind -= step;
    }
    if (step === 1) {
      break;
    }
    step = Math.ceil(step / 2);
  }
  if (getNumLines(div, text, ind, ellipsis) === 1) {
    return ind;
  } else {
    return ind - 1;
  }
};

const getNumCharactersInLine = (
  text: string,
  div: HTMLDivElement,
  ellipsis: string | null
) => {
  div.style.width = `${LINE_WIDTH}px`;
  div.style.lineHeight = "10px";
  div.style.fontSize = `${FONT_SIZE}px`;

  if (ellipsis) {
    return getNumCharactersInLineSingleWord(text, div, ellipsis);
  }

  let ind = 0;
  while (true) {
    let whitespace = text.indexOf(" ", ind);
    if (whitespace === -1) {
      whitespace = text.length;
    }
    if (getNumLines(div, text, whitespace, "") > 1) {
      if (ind === 0) {
        // Split up a really long word
        return getNumCharactersInLineSingleWord(text, div, "");
      } else {
        // We've exceeded 1 line, so return the previous whitespace
        return ind - 1;
      }
    } else if (whitespace === text.length) {
      // If the full text fits on one line
      return text.length;
    }
    ind = whitespace + 1;
  }
};

const getLines = (text: string, div: HTMLDivElement | null) => {
  if (div === null || !text) {
    return [];
  }
  const firstLineEnd = getNumCharactersInLine(text, div, null);
  const firstLine = text.substring(0, firstLineEnd);
  const remainder = text.substring(firstLineEnd).trim();
  if (!remainder) {
    return [firstLine];
  }
  let secondLine;
  if (getNumLines(div, remainder, remainder.length, "") === 1) {
    secondLine = remainder;
  } else {
    const secondLineEnd = getNumCharactersInLine(remainder, div, "...");
    secondLine = remainder.substring(0, secondLineEnd).trim() + "...";
  }

  return [firstLine, secondLine];
};

type ItemActivityPreviewProps = {
  itemId: string;
  updateThumbnailKey: (resourceId: string, thumbnailKey: string) => void;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
};

const ItemActivityPreview = ({
  itemId,
  updateThumbnailKey,
  canvasRef,
}: ItemActivityPreviewProps) => {
  const [text, setText] = useState<string[]>();
  const hiddenDivForLineSizingRef = useRef<HTMLDivElement>(null);
  const roomItems = useSelector(selectRoomItems);
  const editItemId = useSelector(selectEditItemId);
  const item = roomItems?.find((i) => i.id === itemId);

  const resource = item?.resource;
  const resourceName = resource?.name;

  useEffect(() => {
    if (resourceName) {
      setText(getLines(resourceName, hiddenDivForLineSizingRef.current));
    }
  }, [resourceName]);

  if (!canvasRef.current || !resource) {
    return null;
  }

  const zoom = canvasRef.current.getZoom();
  const size = PREVIEW_SIZE * zoom;
  const strokeWidth = PREVIEW_STROKE_WIDTH * zoom;
  const fontSize = FONT_SIZE * zoom;
  const leadLineLength = Math.sqrt(2 * Math.pow(size / 2, 2));

  const currentObjects = canvasRef.current?.getObjects() || [];
  // @ts-ignore
  const canvasItem = currentObjects.find((obj) => obj.itemId === itemId);

  if (!canvasItem || !canvasRef.current?.height || !canvasRef.current?.width) {
    return null;
  }

  const itemTop = canvasItem.top || 0;
  const itemLeft = canvasItem.left || 0;
  const itemHeight = canvasItem.getScaledHeight() || 0;
  const itemWidth = canvasItem.getScaledWidth() || 0;
  const verticalCenterOfItem = itemTop + itemHeight * 0.5;
  const horizontalCenterOfItem = itemLeft + itemWidth / 2;
  const horizontalPadding = itemWidth * 0.25;
  const verticalPadding = itemHeight * 0.25;

  // Location of preview: from middle of item, up and right if it will fit, otherwise down or left
  let previewTop = verticalCenterOfItem - PREVIEW_SIZE - verticalPadding;
  const leadLineCenterTransform = `translateY(${strokeWidth / 2}px)`;
  if (previewTop <= 0) {
    previewTop = verticalCenterOfItem + verticalPadding;
  }
  let previewLeft = horizontalCenterOfItem + horizontalPadding;
  if (previewLeft + PREVIEW_SIZE >= canvasRef.current.getWidth() / zoom) {
    previewLeft = horizontalCenterOfItem - PREVIEW_SIZE - horizontalPadding;
  }
  let leadLineRotation;
  if (previewTop < verticalCenterOfItem) {
    if (previewLeft < horizontalCenterOfItem) {
      // top left
      leadLineRotation = 45;
    } else {
      // top right
      leadLineRotation = 135;
    }
  } else {
    if (previewLeft < horizontalCenterOfItem) {
      // hottom left
      leadLineRotation = -45;
    } else {
      // bottom right
      leadLineRotation = -135;
    }
  }
  const leadLineTransform =
    leadLineCenterTransform + ` rotate(${leadLineRotation}deg)`;

  return (
    <div
      className={clsx(styles.container, {
        [styles.noPointerEvents]: editItemId !== itemId,
      })}
      style={{ top: previewTop * zoom, left: previewLeft * zoom }}
    >
      <div
        className={styles.leadLine}
        style={{
          width: leadLineLength,
          height: strokeWidth,
          borderRadius: strokeWidth,
          transform: leadLineTransform,
        }}
      />
      <div
        className={styles.circle}
        style={{
          height: size,
          width: size,
          borderRadius: size / 4,
          borderWidth: strokeWidth,
        }}
      >
        <ItemActivityThumbnail
          resource={resource}
          updateThumbnailKey={updateThumbnailKey}
        />
        <div className={styles.transparentOverlay} />
        <div className={styles.textContainer} style={{ fontSize }}>
          {text?.map((line, index) => (
            <div key={index}>
              {line}
              <br />
            </div>
          ))}
        </div>
        <div
          className={clsx(styles.buttonContainer, {
            [styles.transparent]: itemId !== editItemId,
          })}
        />
      </div>
      {itemId === editItemId ? <ItemButtons iconSize={size * 0.15} /> : null}
      <div
        ref={hiddenDivForLineSizingRef}
        className={styles.hiddenTextDivForSizing}
      />
    </div>
  );
};

export default ItemActivityPreview;
