import React from "react";
import fileIcon from "assets/icons/file.svg";
import websiteIcon from "assets/icons/world.svg";
import styles from "./ResourceThumbnail.module.css";
import clsx from "clsx";
import { ResourceType } from "../../../utils/drawingUtils";

type ResourceThumbnailProps = {
  thumbnailSrc: string | null | undefined;
  resourceType: ResourceType;
};

const ResourceThumbnail = ({
  thumbnailSrc,
  resourceType,
}: ResourceThumbnailProps) => {
  if (thumbnailSrc) {
    // Note the crossOrigin attribute needs to come before src, see https://github.com/facebook/react/issues/14035
    return (
      <div className={clsx(styles.container, styles.thumbnailContainer)}>
        <img
          className={styles.thumbnailImage}
          crossOrigin={"anonymous"}
          src={thumbnailSrc}
        />
      </div>
    );
  } else {
    const icon = resourceType === ResourceType.FILE ? fileIcon : websiteIcon;
    return (
      <div className={clsx(styles.container, styles.fallbackContainer)}>
        <img className={styles.fallbackIcon} src={icon} />
      </div>
    );
  }
};

export default ResourceThumbnail;
