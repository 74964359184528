import React from "react";

import styles from "./ClientConfirmationNameModal.module.css";
import Modal from "components/Modal/Modal";
import Button from "components/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import {
  admitClient,
  cancelNameConfirmationModal,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  selectIncomingClient,
  selectSelectingClient,
} from "redux/clientManagementRedux";
import { useAdmitClient } from "utils/waitingRoomUtils";
import { ReactComponent as WarnIcon } from "assets/icons/warning.svg";
import { selectUserId } from "redux/userRedux";
import { useSetCurrentClientMutation } from "generated/graphql";
import { useLogClientManagementEvent } from "utils/metricsUtils";

type ClientConfirmationNameModalProps = {};

const ClientConfirmationNameModal = ({}: ClientConfirmationNameModalProps) => {
  const dispatch = useDispatch();

  const incomingClient = useSelector(selectIncomingClient);
  const selectingClient = useSelector(selectSelectingClient);

  const admitClientOnServer = useAdmitClient();

  const providerId = useSelector(selectUserId);
  const [setCurrentClient] = useSetCurrentClientMutation();

  const logClientManagementEvent = useLogClientManagementEvent();
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);
  const previouslySelectedClient = useSelector(selectCurrentClient);

  const closeModal = () => {
    dispatch(cancelNameConfirmationModal());
  };

  const handleSubmit = async () => {
    // should not be called without a client selected
    if (!selectingClient) {
      throw new Error("Cannot select no client.");
    }

    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "NAME_CONFIRMATION_OK",
      clientName: selectingClient.name,
      incomingName: incomingClient?.name ?? "",
    });

    await admitClientOnServer({});
    const result = await setCurrentClient({
      variables: {
        clientId: selectingClient.canonical_id,
        providerId: providerId,
      },
    });
    if (
      result.errors ||
      !result.data?.update_user_by_pk?.current_client_canonical_id
    ) {
      alert("There was an error loading the client. Please try again.");
      return;
    }
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "CLIENT_ADMITTED",
      clientCanonicalId: selectingClient.canonical_id,
      previousClientCanonicalId: previouslySelectedClient?.canonical_id,
      duringSession: clientHasJoinedRoom,
    });
    dispatch(admitClient(selectingClient));
  };

  const handleCancel = () => {
    // not awaiting to let it run in the background
    logClientManagementEvent({
      action: "NAME_CONFIRMATION_CANCEL",
      clientName: selectingClient?.name ?? "",
      incomingName: incomingClient?.name ?? "",
    });
    closeModal();
  };

  return (
    <Modal closeModal={handleCancel}>
      <div className={styles.container}>
        <div className={styles.heading}>
          <WarnIcon className={styles.warnIcon} />
          Wrong Client?
        </div>
        <div className={styles.subheading}>
          The name of the client you are loading doesn&apos;t seem to match the
          name entered by the client.
        </div>

        <div className={styles.nameDisplayLabel}>Name entered by client</div>
        <div className={styles.nameDisplay}>{incomingClient?.name}</div>

        <div className={styles.nameDisplayLabel}>Selected client</div>
        <div className={clsx(styles.nameDisplay, styles.warn)}>
          {selectingClient?.name}
        </div>
        <div className={styles.confirmMessage}>
          Are you sure you want to load this client?
        </div>

        <div className={styles.buttonRow}>
          <Button onClick={handleCancel}>Go Back</Button>

          <Button
            className={styles.confirmButton}
            onClick={handleSubmit}
            secondary
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default ClientConfirmationNameModal;
