import React from "react";
import styles from "./ControlButton.module.css";
import clsx from "clsx";
import { fabricTypes } from "utils/fabric-impl";
import { COLORS } from "teleoConstants";

import deleteIcon from "assets/icons/eraser.svg";
import zoomOutIcon from "assets/icons/zoom_out.svg";
import zoomInIcon from "assets/icons/zoom_in.svg";

import {
  ERASER_BRUSH_HEIGHT_MULTIPLIER,
  getEraserBrush,
  getPaintBrush,
} from "pages/Space/subpages/SpaceRoom/utils/drawingUtils";
import TextButton, { TextSize } from "./TextButton";
import { TOOLBAR_BUTTON_BORDER_MULTIPLIER } from "./WhiteboardControls";
import { zoomLevelType } from "pages/Space/subpages/Whiteboard/whiteboard.d";

export enum ControlButtonType {
  TEXT,
  FREE_DRAW,
  DELETE,
  ZOOM,
}

export type BaseConfig = {
  type: ControlButtonType;
};

interface FreeDrawConfig extends BaseConfig {
  type: ControlButtonType.FREE_DRAW;
  color: string;
}

interface TextConfig extends BaseConfig {
  type: ControlButtonType.TEXT;
}

interface DeleteConfig extends BaseConfig {
  type: ControlButtonType.DELETE;
}

interface ZoomConfig extends BaseConfig {
  type: ControlButtonType.ZOOM;
}

export type ControlButtonConfig =
  | FreeDrawConfig
  | TextConfig
  | DeleteConfig
  | ZoomConfig;

type ControlButtonProps = {
  config: ControlButtonConfig;
  isSelected: boolean;
  setIsSelected: () => void;
  canvasRef: React.MutableRefObject<fabricTypes.Canvas | undefined>;
  focusedTextRef: React.MutableRefObject<fabricTypes.IText | undefined>;
  textSizeRef: React.MutableRefObject<TextSize>;
  emitBrushChange: (color: string) => void;
  buttonHeight: number;
  visibleHeight: number;
  setCursorRadius: (radius: number) => void;
  setShowCursor: (show: boolean) => void;
  is2xZoomEnabled: boolean;
  is3xZoomEnabled: boolean;
  zoomLevel: zoomLevelType;
  setZoomLevel: (value: zoomLevelType) => void;
};

const ControlButton = ({
  config,
  isSelected,
  setIsSelected,
  canvasRef,
  focusedTextRef,
  textSizeRef,
  emitBrushChange,
  buttonHeight,
  visibleHeight,
  setCursorRadius,
  setShowCursor,
  is2xZoomEnabled,
  is3xZoomEnabled,
  zoomLevel,
  setZoomLevel,
}: ControlButtonProps) => {
  const onClick = () => {
    if (!isSelected && canvasRef.current) {
      if (config.type !== ControlButtonType.ZOOM) {
        setIsSelected();
      }
      if (config.type !== ControlButtonType.TEXT) {
        // Unfocus any text elements being edited
        if (focusedTextRef.current) {
          focusedTextRef.current.exitEditing();
          focusedTextRef.current = undefined;
        }
      }

      if (config.type === ControlButtonType.FREE_DRAW) {
        canvasRef.current.isDrawingMode = true;
        canvasRef.current.freeDrawingBrush = getPaintBrush(
          config.color,
          visibleHeight,
          canvasRef.current
        );
        canvasRef.current.freeDrawingCursor = "crosshair";
        setShowCursor(false);
        emitBrushChange(config.color);
      }
      if (config.type === ControlButtonType.TEXT) {
        canvasRef.current.isDrawingMode = false;
        canvasRef.current.defaultCursor = "text";
        canvasRef.current.hoverCursor = "text";
        setShowCursor(false);
      }
      if (config.type === ControlButtonType.DELETE) {
        canvasRef.current.isDrawingMode = true;
        canvasRef.current.freeDrawingCursor = "none";
        canvasRef.current.freeDrawingBrush = getEraserBrush(
          visibleHeight,
          canvasRef.current
        );
        setCursorRadius((visibleHeight * ERASER_BRUSH_HEIGHT_MULTIPLIER) / 2);
        setShowCursor(true);
      }
      if (config.type === ControlButtonType.ZOOM) {
        let nextZoomLevel: zoomLevelType;
        switch (zoomLevel) {
          case "1x":
            nextZoomLevel = is2xZoomEnabled ? "2x" : "3x";
            setZoomLevel(nextZoomLevel);
            break;
          case "2x":
            nextZoomLevel = is3xZoomEnabled ? "3x" : "1x";
            setZoomLevel(nextZoomLevel);
            break;
          case "3x":
            setZoomLevel("1x");
            break;
        }
      }
    }
  };

  const innerButtonHeight =
    (1 - 2 * TOOLBAR_BUTTON_BORDER_MULTIPLIER) * buttonHeight;
  const buttonStyle = {
    height: innerButtonHeight,
    width: innerButtonHeight,
    borderRadius: innerButtonHeight,
    margin: isSelected ? 0 : TOOLBAR_BUTTON_BORDER_MULTIPLIER * buttonHeight,
    fontSize: (38 / 70) * buttonHeight,
    borderWidth: isSelected
      ? TOOLBAR_BUTTON_BORDER_MULTIPLIER * buttonHeight
      : 0,
  };
  const iconStyle = {
    height: (4 / 7) * buttonHeight,
    width: (4 / 7) * buttonHeight,
  };

  if (config.type === ControlButtonType.FREE_DRAW) {
    return (
      <button
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: config.color }}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.TEXT) {
    return (
      <TextButton
        isSelected={isSelected}
        buttonStyle={buttonStyle}
        textSizeRef={textSizeRef}
        onClick={onClick}
      />
    );
  }

  if (config.type === ControlButtonType.DELETE) {
    return (
      <button
        id={"eraserButton"}
        className={clsx(styles.button, { [styles.isSelected]: isSelected })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        onClick={onClick}
      >
        <img src={deleteIcon} style={iconStyle} />
      </button>
    );
  }

  if (config.type === ControlButtonType.ZOOM) {
    const sourceIcon =
      zoomLevel === "3x" || !is3xZoomEnabled ? zoomOutIcon : zoomInIcon;
    const isZoomEnabled =
      is2xZoomEnabled || is3xZoomEnabled || zoomLevel !== "1x";
    return (
      <button
        id={isZoomEnabled ? "zoomButton" : undefined}
        className={clsx(styles.button, { [styles.hidden]: !isZoomEnabled })}
        style={{ ...buttonStyle, backgroundColor: COLORS.GRAY }}
        onClick={onClick}
      >
        <img src={sourceIcon} style={iconStyle} />
      </button>
    );
  }

  return null;
};

export default ControlButton;
