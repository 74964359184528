import React, { useEffect, useMemo } from "react";
import styles from "./ClientSelectionList.module.css";
import {
  Client,
  openNewClient,
  selectClientSearchText,
  setClientSearchText,
} from "redux/clientManagementRedux";
import clsx from "clsx";
import { ReactComponent as XIcon } from "assets/icons/x.svg";
import TextInput from "components/TextInput/TextInput";
import Button from "components/Button/Button";
import { ReactComponent as SearchIcon } from "assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "assets/icons/plus.svg";
import { useDispatch, useSelector } from "react-redux";
import { createFuseInstance } from "utils/fuseUtils";
import Tooltip from "components/Tooltip/Tooltip";

type ListItemProps = {
  client: Client;
  onChange: (value: Client | undefined) => void;
  isSelected: boolean;
};

const ListItem = ({ client, onChange, isSelected }: ListItemProps) => {
  return (
    <li
      onClick={() => onChange(client)}
      className={clsx(styles.listItem, {
        [styles.selected]: isSelected,
      })}
      title={client.name}
    >
      {client.name}
    </li>
  );
};

type ClientSelectionListProps = {
  clientList: Client[];
  className?: string;
  value?: Client;
  onChange: (
    value?: Client,
    from?: "fromFilteredList" | "fromFullList"
  ) => void;
  initialFilter?: string;
  showNewClientButton?: boolean;
};

const ClientSelectionList = ({
  clientList,
  className,
  value,
  onChange,
  initialFilter,
  showNewClientButton = true,
}: ClientSelectionListProps) => {
  const dispatch = useDispatch();
  const searchText = useSelector(selectClientSearchText);

  const fuseClientList = useMemo(() => {
    return createFuseInstance(clientList);
  }, [clientList]);

  const filteredList = useMemo(() => {
    if (searchText === "") {
      return [];
    }
    const fuseResult = fuseClientList.search(searchText);
    return fuseResult.map((fuseClient) => fuseClient.item);
  }, [searchText, fuseClientList]);

  const openCreateClient = () => {
    dispatch(openNewClient());
  };

  useEffect(() => {
    if (!searchText && initialFilter) {
      dispatch(setClientSearchText(initialFilter));
    }
  }, [initialFilter]);

  return (
    <div className={clsx(styles.container, className)}>
      <div className={styles.searchInput}>
        <TextInput
          placeholder="Name"
          value={searchText}
          setValue={(value) => dispatch(setClientSearchText(value))}
        />
        <SearchIcon className={styles.icon} />
      </div>
      {showNewClientButton && (
        <Button
          onClick={openCreateClient}
          className={styles.newClient}
          id="newClientButton"
        >
          <PlusIcon className={styles.plusIcon} />
          New Client
        </Button>
      )}
      <ul className={styles.list}>
        {filteredList.length > 0 && (
          <>
            <div className={styles.listLabel}>Search Results</div>
            {filteredList.map((client) => (
              <ListItem
                client={client}
                onChange={(value) => onChange(value, "fromFilteredList")}
                isSelected={client.canonical_id === value?.canonical_id}
                key={`filtered-${client.canonical_id}`}
              />
            ))}
            <div className={styles.separator}></div>
          </>
        )}

        {filteredList.length === 0 && searchText !== "" && (
          <>
            <div className={styles.noMatch}>No Matches</div>
            <div className={styles.separator}></div>
          </>
        )}
        <div className={styles.listLabel}>All Clients</div>
        {clientList.map((client) => (
          <ListItem
            client={client}
            onChange={(value) => onChange(value, "fromFullList")}
            isSelected={client.canonical_id === value?.canonical_id}
            key={client.canonical_id}
          />
        ))}
      </ul>
      <div className={styles.selectedClient}>
        <label className={styles.selectedLabel}>Selected</label>
        <Tooltip
          text={value?.name}
          elementWrapperClassName={styles.tooltipWrapperDiv}
        >
          <span
            className={clsx(styles.clientSelection, {
              [styles.empty]: !value,
            })}
          >
            {value?.name ?? "Select a client from the list above"}
            {!!value && (
              <XIcon
                className={styles.xIcon}
                onClick={() => onChange(undefined, undefined)}
              />
            )}
          </span>
        </Tooltip>
      </div>
    </div>
  );
};

export default ClientSelectionList;
