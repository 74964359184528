import React, { useEffect, useMemo, useState } from "react";

import { ReactComponent as XIcon } from "assets/icons/x.svg";
import { ReactComponent as DeleteIcon } from "assets/icons/delete.svg";
import { ReactComponent as SwitchIcon } from "assets/icons/switch_person.svg";

import styles from "./ClientFile.module.css";
import { useDispatch, useSelector } from "react-redux";
import {
  SnapshotDetail,
  openLoadModal,
  selectClientHasJoinedRoom,
  selectCurrentClient,
  setSnapshotDetail,
  toggleClientFileOpen,
  toggleShowConfirmClientDelete,
} from "redux/clientManagementRedux";
import clsx from "clsx";
import {
  GetAlbumSnapshotsDocument,
  useGetSnapshotsQuery,
  useSetSnapshotSharedWithClientMutation,
} from "generated/graphql";
import { getFileUrls } from "utils/fileUtils";
import { selectDecodedAuthToken } from "redux/userRedux";
import ClientDeleteConfirmModal from "./ClientDeleteConfirmModal";
import {
  useLogClientManagementEvent,
  useLogSnapshotSharedCount,
} from "utils/metricsUtils";
import albumIcon from "assets/itemIcons/album.png";
import { logUnexpectedError } from "utils/errorUtils";
import { selectSessionEHRSystem } from "redux/ehrSystemRedux";

type SnapshotList = {
  month: { label: string; id: string };

  days: {
    label: string;
    id: string;
    snapshots: {
      id: string;
      fileKey: string;
      thumbnailKey: string;
      resourceName: string;
      clientName: string;
      createdAt: string;
      sharedWithClient: boolean;
    }[];
  }[];
}[];

const ClientFile = () => {
  const [imgKeysToURL, setImgKeysToURL] = useState<{ [key: string]: string }>(
    {}
  );
  const currentClient = useSelector(selectCurrentClient);
  const ehrSystem = useSelector(selectSessionEHRSystem);
  const decodedAuthToken = useSelector(selectDecodedAuthToken);

  const { data } = useGetSnapshotsQuery({
    variables: {
      clientCanonicalId: currentClient?.canonical_id,
    },
  });
  const dispatch = useDispatch();

  const logClientManagementEvent = useLogClientManagementEvent();
  const clientHasJoinedRoom = useSelector(selectClientHasJoinedRoom);

  const [setSnapshotSharedWithClient] =
    useSetSnapshotSharedWithClientMutation();

  const logSnapshotSharedCount = useLogSnapshotSharedCount();

  useEffect(() => {
    if (currentClient) {
      // not awaiting to let it run in the background
      logClientManagementEvent({
        action: "CLIENT_SIDEBAR_OPENED",
        clientCanonicalId: currentClient.canonical_id,
        duringSession: clientHasJoinedRoom,
      });
    }
  }, []);

  const [snapshotList, imgKeys]: [SnapshotList, string[]] = useMemo(() => {
    if (data?.resource_snapshot) {
      const imgKeys = [];
      const snapshotList: SnapshotList = [];
      for (const snapshot of data?.resource_snapshot) {
        const snapshotDate = new Date(snapshot.created_at);
        const month = snapshotDate.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
        });

        let currentMonth = snapshotList[snapshotList.length - 1];
        if (!currentMonth || currentMonth.month.id !== month) {
          currentMonth = {
            month: {
              label: `${snapshotDate.toLocaleString("en-US", {
                month: "long",
              })} ${snapshotDate.toLocaleString("en-US", { year: "numeric" })}`,
              id: month,
            },
            days: [],
          };
          snapshotList.push(currentMonth);
        }

        const day = snapshotDate.toLocaleString("en-US", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });

        let currentDay = currentMonth.days[currentMonth.days.length - 1];
        if (!currentDay || currentDay.id !== day) {
          const dateString = snapshotDate.toLocaleString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          const todayDateString = new Date().toLocaleString("en-US", {
            year: "2-digit",
            month: "numeric",
            day: "numeric",
          });
          const isToday = dateString === todayDateString;
          currentDay = {
            id: day,
            label: dateString + (isToday ? " - Current Session" : ""),
            snapshots: [],
          };
          currentMonth.days.push(currentDay);
        }
        currentDay.snapshots.push({
          id: snapshot.id,
          fileKey: snapshot.file_key,
          thumbnailKey: snapshot.thumbnail_file_key,
          resourceName: snapshot.resource.name ?? "",
          clientName: snapshot.client.name,
          createdAt: snapshot.created_at,
          sharedWithClient: snapshot.shared_with_client,
        });
        imgKeys.push(snapshot.file_key, snapshot.thumbnail_file_key);
      }
      return [snapshotList, imgKeys];
    } else {
      return [[], []];
    }
  }, [data?.resource_snapshot]);

  useEffect(() => {
    const getURLsFromKeys = async () => {
      setImgKeysToURL(await getFileUrls(imgKeys, "snapshot"));
    };
    getURLsFromKeys();
  }, [imgKeys]);

  const handleSnapshotClick = (snapshot: SnapshotDetail) => {
    dispatch(setSnapshotDetail(snapshot));
  };

  const handleShareClick = async (
    snapshotId: string,
    isCurrentlyShared: boolean
  ) => {
    const result = await setSnapshotSharedWithClient({
      variables: {
        snapshotId: snapshotId,
        shared: !isCurrentlyShared,
      },
      refetchQueries: [
        {
          query: GetAlbumSnapshotsDocument,
          variables: { clientId: currentClient?.canonical_id },
        },
      ],
    });
    if (!result.data?.update_resource_snapshot_by_pk?.id) {
      logUnexpectedError(
        new Error("Failed to set snapshot as shared with client")
      );
      return;
    }
    logSnapshotSharedCount();
  };

  return !!currentClient ? (
    <div className={styles.container}>
      <div className={styles.closeButton}>
        <XIcon
          className={styles.XIcon}
          onClick={() => dispatch(toggleClientFileOpen())}
        />
      </div>
      <div className={styles.name}>{currentClient.name}</div>
      <div className={styles.actionButtons}>
        {(!ehrSystem || !decodedAuthToken.epicConferenceID) && (
          <div
            className={styles.actionButton}
            onClick={() => dispatch(openLoadModal())}
          >
            <SwitchIcon className={styles.switchIcon} />
            Switch
          </div>
        )}
        {!ehrSystem && (
          <div
            className={clsx(styles.actionButton, styles.deleteButton)}
            onClick={() => dispatch(toggleShowConfirmClientDelete())}
          >
            <ClientDeleteConfirmModal />
            <DeleteIcon />
            Delete
          </div>
        )}
      </div>

      <div className={styles.snapshotListContainer}>
        {snapshotList.length > 0 ? (
          snapshotList.map((month) => (
            <div key={month.month.label} className={styles.monthContainer}>
              <div className={styles.monthLabel}>{month.month.label}</div>
              <div>
                {month.days.map((day) => (
                  <div key={day.id} className={styles.dayContainer}>
                    {day.label}
                    <div className={styles.snapshotList}>
                      {day.snapshots.map((snapshot) => (
                        <div
                          key={snapshot.id}
                          className={styles.snapshot}
                          onClick={() =>
                            handleSnapshotClick({
                              clientName: snapshot.clientName,
                              id: snapshot.id,
                              fileKey: snapshot.fileKey,
                              fileUrl: imgKeysToURL[snapshot.fileKey],
                              resource: snapshot.resourceName,
                              created_at: snapshot.createdAt,
                              sharedWithClient: snapshot.sharedWithClient,
                            })
                          }
                        >
                          <div className={styles.snapshotImgContainer}>
                            <img src={imgKeysToURL[snapshot.thumbnailKey]} />
                            <div
                              className={clsx(styles.shareButton, {
                                [styles.isShared]: snapshot.sharedWithClient,
                              })}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleShareClick(
                                  snapshot.id,
                                  snapshot.sharedWithClient
                                );
                              }}
                            >
                              <img src={albumIcon} />
                            </div>
                          </div>
                          <div className={styles.snapshotResourceName}>
                            {snapshot.resourceName}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <div className={styles.emptyMessage}>
            <p>No snapshots have been taken.</p>
            <p>
              Open an activity and click “Snapshot” in the bottom control bar to
              save a snapshot to the client record.
            </p>
          </div>
        )}
      </div>
    </div>
  ) : (
    <></>
  );
};

export default ClientFile;
