import React, { ChangeEvent, KeyboardEvent, useRef, useState } from "react";
import searchIcon from "assets/icons/search.svg";
import styles from "./SearchBar.module.css";
import clsx from "clsx";
import xIcon from "assets/icons/x.svg";

type SearchBarProps = {
  setSearch: (value: string) => void;
  smallWidth?: boolean;
};

const SearchBar = ({ setSearch, smallWidth }: SearchBarProps) => {
  const [searchValue, setSearchValue] = useState("");
  const [hasBeenModifiedSinceSubmit, setHasBeenModifiedSinceSubmit] =
    useState(false);
  const inputElementRef = useRef<HTMLInputElement>(null);

  const textChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
    setHasBeenModifiedSinceSubmit(true);
  };

  const submitSearch = () => {
    setSearch(searchValue);
    setHasBeenModifiedSinceSubmit(false);
  };

  const detectEnter = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      submitSearch();
      inputElementRef.current?.blur();
    }
  };

  const clearSearch = () => {
    setSearchValue("");
    if (!hasBeenModifiedSinceSubmit) {
      setSearch("");
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.textContainer}>
        <input
          ref={inputElementRef}
          type="text"
          name="search"
          value={searchValue}
          placeholder={"Search"}
          className={clsx(styles.textInput, {
            [styles.textInputSmallWidth]: smallWidth,
          })}
          onChange={textChangeHandler}
          onKeyUp={detectEnter}
        />
        {searchValue ? (
          <div className={styles.clearButton} onClick={clearSearch}>
            <img className={styles.xIcon} src={xIcon} />
          </div>
        ) : null}
      </div>
      <div
        className={clsx(styles.searchButton, {
          [styles.activeSearchButton]: hasBeenModifiedSinceSubmit,
        })}
        onClick={hasBeenModifiedSinceSubmit ? submitSearch : undefined}
      >
        <img className={styles.searchIcon} src={searchIcon} />
      </div>
    </div>
  );
};

export default SearchBar;
