import React from "react";

import styles from "./ConnectionError.module.css";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import Button from "components/Button/Button";
import clsx from "clsx";
import CopyButton from "pages/Space/components/CopyButton/CopyButton";
import { useSelector } from "react-redux";
import DelayedButton from "pages/Space/components/DelayedButton/DelayedButton";
import { getOrganizationConfig } from "utils/organizationUtils";
import { selectOrganizationId } from "redux/userRedux";

type ConnectionErrorProps = {
  errorMessage: string;
  loading: boolean;
  showInviteLink: boolean;
  showSignInButton?: boolean;
  showClientRejoin?: boolean;
  showSurvey?: boolean;
} & ( // if showReconnect is passed, userType and endSession must also be passed
  | {
      showReconnect: boolean;
      userType: "provider" | "client";
      endSession: () => void;
    }
  | { showReconnect?: never; userType?: never; endSession?: never }
);

const ConnectionError = ({
  errorMessage,
  loading,
  showInviteLink,
  showSignInButton,
  showClientRejoin,
  showSurvey,
  showReconnect,
  userType,
  endSession,
}: ConnectionErrorProps) => {
  const hidden = !errorMessage;
  const organizationId = useSelector(selectOrganizationId);
  const organizationConfig = getOrganizationConfig(organizationId);

  const signIn = () => {
    if (process.env.REACT_APP_HOME_URL) {
      location.href = `https://${process.env.REACT_APP_HOME_URL}`;
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  return (
    <div
      className={clsx(styles.container, {
        [styles.hidden]: hidden,
        // Re-enable to show the transparent background when needed
        // [styles.transparent]: showReconnect,
      })}
    >
      {loading ? <LoadingAnimation /> : null}
      {errorMessage}
      {showInviteLink ? (
        <div className={styles.inviteSection}>
          Invite your client to join with this link:
          <div className={styles.linkRow}>
            <div className={styles.link}>{window.location.href}</div>
            <CopyButton textToCopy={window.location.href}></CopyButton>
          </div>
        </div>
      ) : null}
      {showSurvey && organizationConfig?.clientSurveyUrl ? (
        <>
          <p>Help us make things better by answering a few questions!</p>
          <div className={styles.buttonGroup}>
            <Button
              className={styles.signInButton}
              href={organizationConfig?.clientSurveyUrl}
            >
              Start Survey
            </Button>
            {showClientRejoin && (
              <Button
                onClick={reloadPage}
                className={styles.resetRoomButton}
                secondary
              >
                Rejoin
              </Button>
            )}
          </div>
        </>
      ) : (
        showClientRejoin && (
          <Button
            onClick={reloadPage}
            className={styles.resetRoomButton}
            secondary
          >
            Rejoin
          </Button>
        )
      )}
      {showSignInButton ? (
        <Button className={styles.signInButton} onClick={signIn}>
          Sign In Again
        </Button>
      ) : null}

      {showReconnect &&
        (userType === "provider" ? (
          <div className={styles.buttonGroup}>
            <Button
              onClick={reloadPage}
              className={clsx(styles.resetRoomButton, styles.largeButton)}
            >
              Reload and Reconnect
            </Button>
            <Button
              onClick={endSession}
              className={clsx(styles.resetRoomButton, styles.largeButton)}
              secondary
            >
              End Session and Reset
            </Button>
          </div>
        ) : (
          <DelayedButton
            onClick={reloadPage}
            className={clsx(styles.resetRoomButton, styles.largeButton)}
            delay={30000}
          >
            Reload and Restart
          </DelayedButton>
        ))}
    </div>
  );
};

export default ConnectionError;
