import {
  ALBUM_RESOURCE_ID,
  MANDALA_RESOURCE_ID,
  OLDER_FEELINGS_CHART_RESOURCE_ID,
  POSTER_RESOURCE_ID,
  ROOM_CUSTOMIZATION_RESOURCE_ID,
  YOUNGER_FEELINGS_CHART_RESOURCE_ID,
} from "utils/resourceUtils";
import React, { useEffect, useRef } from "react";
import { getIconConfig } from "pages/Space/subpages/SpaceRoom/itemsConfig";
import { useSelector } from "react-redux";
import { selectRoomItems } from "redux/spaceNavigationRedux";
import { RoomItemFragment } from "generated/graphql";

// This hook identifies the location of a file item, a website item, and optionally a client album item in the room,
// and generates css styles to use for overlays to use for Pendo guides.

const generateStyleForItem = (
  item: RoomItemFragment | undefined,
  backgroundImageSizeRef: React.MutableRefObject<
    { width: number; height: number } | undefined
  >
) => {
  const style: React.CSSProperties = {};
  if (item && backgroundImageSizeRef.current) {
    style["left"] = `${item.rx * 100 || 0}%`;
    style["top"] = `${item.ry * 100 || 0}%`;
    const icon = getIconConfig(item.icon_id);
    style["width"] = `${
      ((icon.srcWidth * icon.scale) / backgroundImageSizeRef.current.width) *
      100
    }%`;
    style["height"] = `${
      ((icon.srcHeight * icon.scale) / backgroundImageSizeRef.current.height) *
      100
    }%`;
  }
  return style;
};

export const useRoomItemOverlays = (
  backgroundImageSizeRef: React.MutableRefObject<
    { width: number; height: number } | undefined
  >
) => {
  const roomItems = useSelector(selectRoomItems);
  const fileItemIdRef = useRef<string>();
  const websiteItemIdRef = useRef<string>();

  const feelingsChartItem = roomItems?.find(
    (item) =>
      item.resource.id === YOUNGER_FEELINGS_CHART_RESOURCE_ID ||
      item.resource.id === OLDER_FEELINGS_CHART_RESOURCE_ID
  );
  const fileActivityItem =
    feelingsChartItem ||
    roomItems?.find(
      (item) =>
        !item.resource.url &&
        item.resource.id !== POSTER_RESOURCE_ID &&
        item.resource.id !== ROOM_CUSTOMIZATION_RESOURCE_ID
    );
  const fileActivityItemId = fileActivityItem?.id;
  const fileActivityStyle = generateStyleForItem(
    fileActivityItem,
    backgroundImageSizeRef
  );

  useEffect(() => {
    fileItemIdRef.current = fileActivityItemId;
  }, [fileActivityItemId]);

  const mandalaItem = roomItems?.find(
    (item) => item.resource.id === MANDALA_RESOURCE_ID
  );
  const websiteActivityItem =
    mandalaItem || roomItems?.find((item) => !!item.resource.url);
  const websiteActivityItemId = websiteActivityItem?.id;
  const websiteActivityStyle = generateStyleForItem(
    websiteActivityItem,
    backgroundImageSizeRef
  );

  useEffect(() => {
    websiteItemIdRef.current = websiteActivityItemId;
  }, [websiteActivityItemId]);

  const albumItem = roomItems?.find(
    (item) => item.resource.id === ALBUM_RESOURCE_ID
  );
  const hasAlbumItem = !!albumItem;
  const albumActivityStyle = generateStyleForItem(
    albumItem,
    backgroundImageSizeRef
  );

  return {
    fileItemIdRef,
    fileActivityStyle,
    websiteItemIdRef,
    websiteActivityStyle,
    hasAlbumItem,
    albumActivityStyle,
  };
};
