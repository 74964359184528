import React, { useState } from "react";
import DropDown from "../DropDown/DropDown";
import Checkbox from "components/Checkbox/Checkbox";
import styles from "./MultiSelectDropDown.module.css";

type MultiSelectDropDownProps = {
  selectedValues: string[];
  setValues: (value: string[]) => void;
  possibleValues: string[];
  placeholderText: string;
  rightAlign?: boolean;
};

const MultiSelectDropDown = ({
  selectedValues,
  setValues,
  possibleValues,
  placeholderText,
  rightAlign,
}: MultiSelectDropDownProps) => {
  const [newlySelectedValues, setNewlySelectedValues] = useState([
    ...selectedValues,
  ]);

  const cancelSelection = () => {
    setNewlySelectedValues([...selectedValues]);
  };

  const applySelection = () => {
    if (
      JSON.stringify(newlySelectedValues) !== JSON.stringify(selectedValues)
    ) {
      setValues([...newlySelectedValues]);
    }
  };

  const clearSelection = () => {
    if (selectedValues.length > 0) {
      setValues([]);
    }
    if (newlySelectedValues.length > 0) {
      setNewlySelectedValues([]);
    }
  };

  const onToggle = (valueIndex: number) => (isChecked: boolean) => {
    const value = possibleValues[valueIndex];
    if (isChecked && !newlySelectedValues.includes(value)) {
      setNewlySelectedValues([...newlySelectedValues, value].sort());
    }
    if (!isChecked && newlySelectedValues.includes(value)) {
      setNewlySelectedValues(
        newlySelectedValues.filter((val) => val !== value)
      );
    }
  };

  const value = selectedValues.length ? selectedValues.join(", ") : null;

  return (
    <DropDown
      placeholderText={placeholderText}
      value={value}
      cancelSelection={cancelSelection}
      applySelection={applySelection}
      clearSelection={clearSelection}
      rightAlign={rightAlign}
    >
      <div className={styles.body}>
        {possibleValues.map((value, index) => (
          <Checkbox
            key={index}
            onToggle={onToggle(index)}
            checked={newlySelectedValues.includes(value)}
          >
            {value}
          </Checkbox>
        ))}
      </div>
    </DropDown>
  );
};

export default MultiSelectDropDown;
