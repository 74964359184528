import React from "react";

import { Video } from "./Video";
import { Audio } from "./Audio";
import styles from "./VideoOrError.module.css";
import clsx from "clsx";
import { selectUserRole, UserRole } from "redux/userRedux";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button/Button";
import LoadingAnimation from "components/LoadingAnimation/LoadingAnimation";
import { selectShowVideoBlurLoading } from "redux/spaceNavigationRedux";
import { MutedIndicator } from "./VideosButtons/MutedIndicator";
import { DisableClientVideoButton } from "./VideosButtons/DisableClientVideoButton";
import {
  selectIsPeerVideoOff,
  selectIsVideoOff,
  setIsVideoOff,
} from "redux/settingsRedux";
import { MediaMenuButton } from "./VideosButtons/MediaMenuButton";
import { MuteButton } from "./VideosButtons/MuteButton";
import { isMobileDevice } from "utils/deviceUtils";
import { BlurButton } from "./VideosButtons/BlurButton";
import {
  getLocalPeerId,
  PeerWebRTCObject,
} from "pages/Space/hooks/connection/usePeerWebRTCConnection";
import { ExpandButton } from "./VideosButtons/ExpandButton";
import { CanControlCursorButton } from "./VideosButtons/CanControlCursorButton";
import { getAddBreadcrumb } from "utils/errorUtils";

type VideoOrErrorProps = {
  peerId?: string;
  peer?: PeerWebRTCObject;
  stream: MediaStream | undefined;
  isLocal?: boolean;
  error?: string;
  reloadUserMedia?: () => void;
  loadingUserMedia?: boolean;
  isFullScreen?: boolean;
  setRemoteIsPeerMuted: (isPeerMuted: boolean, peerId?: string) => void;
  setRemoteIsPeerVideoOff: (isPeerVideoOff: boolean, peerId?: string) => void;
  setRemotePeerIdWithControl: (peerId: string) => void;
  setRawVideoDimensions: ({
    width,
    height,
  }: {
    width: number;
    height: number;
  }) => void;
  peerFullScreenDimensions: { width: number; height: number };
  localFullScreenDimensions: { width: number; height: number };
  parentDimensions: { width: number; height: number };
  userRole?: UserRole;
  isLastPeer?: boolean;
  peerCount: number;
  setRemoteFullScreenVideoConferencing: (isFullScreen: boolean) => void;
  showExpandButton?: boolean;
};

const addBreadcrumb = getAddBreadcrumb("videoChat");

export const VideoOrError = ({
  peerId,
  peer,
  isLocal,
  stream,
  error,
  reloadUserMedia,
  loadingUserMedia,
  isFullScreen,
  setRemoteIsPeerMuted,
  setRemoteIsPeerVideoOff,
  setRemotePeerIdWithControl,
  setRawVideoDimensions,
  peerFullScreenDimensions,
  localFullScreenDimensions,
  parentDimensions,
  userRole,
  isLastPeer,
  peerCount,
  setRemoteFullScreenVideoConferencing,
  showExpandButton,
}: VideoOrErrorProps) => {
  const dispatch = useDispatch();
  const localUserRole = useSelector(selectUserRole);
  const isPeerVideoOff = useSelector((state) =>
    selectIsPeerVideoOff(state, peerId ?? "")
  );
  const isLocalVideoOff = useSelector(selectIsVideoOff);

  const showVideoBlurLoading = useSelector(selectShowVideoBlurLoading);

  if (!isLocal && !peer?.isConnected && !peer?.isConnecting && !isFullScreen) {
    return null;
  }

  const hasVideo =
    (isLocal || peer?.isConnected) &&
    stream?.active &&
    stream.getVideoTracks().length > 0;

  // never show audio for local user to avoid audio feedback loop
  const hasAudio =
    !isLocal &&
    peer?.isConnected &&
    stream?.active &&
    stream.getAudioTracks().length > 0;

  const getMessage = () => {
    if ((peerId && isPeerVideoOff) || (isLocal && isLocalVideoOff)) {
      return "Camera off";
    }
    if (peer?.isConnecting) {
      return "Connecting...";
    }

    if (error) {
      return error;
    }

    // When there is a video stream but before the peer gives camera permission
    // or when they close the browser window, right before isConnecting changes
    if (
      hasVideo &&
      !isLocal &&
      stream?.getVideoTracks().every((track) => track.muted)
    ) {
      addBreadcrumb("debug", "stream has video muted", {
        tracks: stream.getTracks().map((track) => ({
          kind: track.kind,
          muted: track.muted,
          id: track.id,
        })),
      });
      return "Connecting...";
    }

    if (hasVideo || (isLocal && !error)) {
      return null;
    }
    const videoIsClient = userRole !== UserRole.THERAPIST;
    if (videoIsClient && peer && !peer.isConnected) {
      return "Client is not connected";
    }

    return `No ${videoIsClient ? "client" : "therapist"} video`;
  };
  const message = getMessage();

  const containerStyle: React.CSSProperties | undefined = isFullScreen
    ? {
        width: isLocal
          ? localFullScreenDimensions.width
          : peerFullScreenDimensions.width,
        height: isLocal
          ? localFullScreenDimensions.height
          : peerFullScreenDimensions.height,
        bottom: isLocal
          ? parentDimensions.height / 4 - peerFullScreenDimensions.height / 4
          : undefined,
        left: isLocal
          ? (peerCount ?? 1) > 2
            ? "50%"
            : Math.max(
                parentDimensions.width / 2 - peerFullScreenDimensions.width / 2,
                localFullScreenDimensions.width / 2 + 30
              )
          : undefined,

        transform: isLocal ? "translateX(-50%)" : undefined,
        margin: isLocal ? undefined : "0 28px",
      }
    : undefined;

  const enableVideoClickHandler = () => {
    if (isLocal) {
      dispatch(setIsVideoOff(false));
      setRemoteIsPeerVideoOff(false, getLocalPeerId());
    } else {
      if (localUserRole === UserRole.THERAPIST) {
        setRemoteIsPeerVideoOff(false, peerId);
      }
    }
  };

  const shouldShowEnableVideoButton =
    message === "Camera off" && isLocal && localUserRole !== UserRole.THERAPIST;
  const shouldShowReloadUserMediaButton =
    isLocal && !!message && message !== "Camera off" && !!reloadUserMedia;

  // Don't show the blur button on mobile devices because the blurring is too resource intensive to run on mobile
  const isMobile = isMobileDevice();

  return (
    <div
      className={clsx(styles.container, "singleVideoContainer", {
        [styles.localContainer]: isLocal,
        ["fullScreenLocalVideo"]: isLocal && isFullScreen,
        [styles.lastPeer]: isLastPeer,
      })}
      style={containerStyle}
    >
      {loadingUserMedia ? (
        <LoadingAnimation />
      ) : message ? (
        <div
          className={clsx(styles.message, {
            [styles.fullScreenMessage]: isFullScreen && !isLocal,
          })}
        >
          {message}
          {hasAudio && <Audio stream={stream} />}
          {shouldShowEnableVideoButton ? (
            <Button onClick={enableVideoClickHandler} className={styles.button}>
              Turn on
            </Button>
          ) : (
            shouldShowReloadUserMediaButton && (
              <Button onClick={reloadUserMedia} className={styles.button}>
                Retry
              </Button>
            )
          )}
        </div>
      ) : (
        <Video
          stream={stream}
          muted={isLocal}
          setRawVideoDimensions={setRawVideoDimensions}
        />
      )}
      <div className={styles.buttonsWrapper}>
        {peerId && (
          <MutedIndicator
            peerId={peerId}
            setRemoteIsPeerMuted={setRemoteIsPeerMuted}
            className={clsx(styles.buttonContainer, styles.mutedIndicator)}
          />
        )}
        {isLocal && (
          <>
            <MediaMenuButton
              className={clsx(styles.buttonContainer, styles.mediaMenuButton)}
            />
            <MuteButton
              setRemoteIsPeerMuted={setRemoteIsPeerMuted}
              className={clsx(styles.buttonContainer, styles.muteButton)}
            />
            {!isMobile && (
              <BlurButton
                className={clsx(styles.buttonContainer, styles.blurButton)}
              />
            )}
          </>
        )}
        {localUserRole === UserRole.THERAPIST && (peerId || isLocal) && (
          <DisableClientVideoButton
            peerId={peerId}
            setRemoteIsPeerVideoOff={setRemoteIsPeerVideoOff}
            className={clsx(styles.buttonContainer, styles.disableVideoButton)}
          />
        )}
        {peerCount > 2 &&
          userRole === UserRole.CLIENT &&
          (peerId || isLocal) && (
            <CanControlCursorButton
              peerId={peerId}
              setRemotePeerIdWithControl={setRemotePeerIdWithControl}
              className={clsx(
                styles.buttonContainer,
                styles.canControlCursorButton
              )}
            />
          )}
      </div>
      {isLocal && showVideoBlurLoading && (
        <div className={styles.blurLoadingIndicator}>
          <LoadingAnimation />
        </div>
      )}
      {showExpandButton && (
        <ExpandButton
          setRemoteFullScreenVideoConferencing={
            setRemoteFullScreenVideoConferencing
          }
          className={styles.expandButtonContainer}
        />
      )}
    </div>
  );
};
