import React from "react";
import styles from "./ResourceCategoryIcon.module.css";
import artIcon from "assets/icons/art.svg";
import gameIcon from "assets/icons/game.svg";
import instructionalIcon from "assets/icons/instructional.svg";
import psychoeducationalIcon from "assets/icons/psychoeducational.svg";
import sandtrayIcon from "assets/icons/sandtray.svg";
import videoIcon from "assets/icons/video.svg";
import whiteboardIcon from "assets/icons/whiteboard.svg";
import worksheetIcon from "assets/icons/worksheet.svg";
import musicIcon from "assets/icons/music.svg";
import bookIcon from "assets/icons/book.svg";
import Tooltip from "components/Tooltip/Tooltip";

type ResourceCategoryIconProps = {
  category: string | null | undefined;
};

const categoryToIcon: { [category: string]: string } = {
  ["Art/Creative"]: artIcon,
  ["EBook"]: bookIcon,
  ["Game"]: gameIcon,
  ["Instructional"]: instructionalIcon,
  ["Music"]: musicIcon,
  ["Sand Tray"]: sandtrayIcon,
  ["Video"]: videoIcon,
  ["Whiteboard"]: whiteboardIcon,
  ["Worksheet/Chart"]: worksheetIcon,
  ["Psychoeducational"]: psychoeducationalIcon,
};

const ResourceCategoryIcon = ({ category }: ResourceCategoryIconProps) => {
  if (!category || !categoryToIcon.hasOwnProperty(category)) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Tooltip text={category}>
        <img className={styles.icon} src={categoryToIcon[category]} />
      </Tooltip>
    </div>
  );
};

export default ResourceCategoryIcon;
