import { useEffect, useRef, useState } from "react";
import io, { Socket } from "socket.io-client";
import { getAddBreadcrumb, logUnexpectedError } from "utils/errorUtils";
import { useSelector } from "react-redux";
import {
  selectEncodedAuthToken,
  selectEncodedClientToken,
} from "redux/userRedux";
import { getLocalPeerId } from "./usePeerWebRTCConnection";

const urlRe = /https?:\/\/[^\/]*/;

const addBreadcrumb = getAddBreadcrumb("socket.remote");

export const useRemoteSocket = (roleString: string) => {
  const socketRef = useRef<Socket>();
  const [connectionError, setConnectionError] = useState("");

  const encodedAuthToken = useSelector(selectEncodedAuthToken);
  const encodedClientToken = useSelector(selectEncodedClientToken);
  const encodedClientTokenRef = useRef(encodedClientToken); // for callback
  useEffect(() => {
    encodedClientTokenRef.current = encodedClientToken;
  }, [encodedClientToken]);
  const containerInstanceRef = useRef("");
  const containerIdRef = useRef("");

  // storing the lastSocketId because the socket.id becomes undefined
  // during temporary disconnections
  const lastSocketIdRef = useRef("initialValue");
  const messageCountRef = useRef(0);

  const getClientOffset = () => {
    const clientOffset = `${lastSocketIdRef.current}-${messageCountRef.current}`;
    messageCountRef.current += 1;
    return clientOffset;
  };

  type SetUpRemoteSocketConnectionProps = {
    containerInstance: string;
    containerId: string;
    onFirstInRoom: (iceServers: any) => void;
    onJoinedRoom: (
      iceServers: any,
      peers: { id: string; role: "therapist" | "client" }[]
    ) => void;
    onSessionEnded: () => void;
    onMessage: (message: any) => void;
  };

  const setUpRemoteSocketConnection = ({
    containerInstance,
    containerId,
    onFirstInRoom,
    onJoinedRoom,
    onSessionEnded,
    onMessage,
  }: SetUpRemoteSocketConnectionProps) => {
    if (socketRef.current) {
      addBreadcrumb(
        "info",
        "skipping setup remote socket because there is already a socket"
      );
      socketRef.current.connect();
      return;
    }

    containerInstanceRef.current = containerInstance;
    containerIdRef.current = containerId;

    const urlHostMatches = containerInstance.match(urlRe);
    if (!urlHostMatches || urlHostMatches.length === 0) {
      logUnexpectedError("Container instance url malformed");
      return;
    }
    const urlHost = urlHostMatches[0];
    const urlPath = containerInstance.substring(urlHost.length);
    const encodedToken = encodedAuthToken || encodedClientTokenRef.current;

    const socket = io(urlHost, {
      transports: ["websocket", "polling"],
      path: `${urlPath}/room/${containerId}/socket.io`,
      closeOnBeforeunload: false, // keep the socket open to send the "bye" event onbeforeunload
      withCredentials: true,
      reconnectionDelayMax: 1500,
      timeout: 3000,
      // enable retries
      ackTimeout: 5000,
      retries: 2 * 60,
      // this is the initial auth object. This will be mutated and
      // the updated object is sent when attempting to reconnect
      auth: {
        peerId: getLocalPeerId(),
        token: encodedToken,
        role: roleString,
        serverOffset: 0,
        // should only be true for the first room socket
        // instance created in this page
        firstSocketInstance: !socketRef.current,
      },
    });
    socketRef.current = socket;

    socket.on("created", onFirstInRoom);
    socket.on("joined", onJoinedRoom);
    socket.on("session ended", onSessionEnded);
    socket.on("message", (message: any, serverOffset: number) => {
      if (!serverOffset) {
        logUnexpectedError("serverOffset not sent");
        return;
      }
      if (typeof socket.auth !== "object") {
        logUnexpectedError("Socket auth not an object");
        return;
      }
      socket.auth.serverOffset = serverOffset;
      onMessage(message);
    });

    socket.on("full", (room) => {
      setConnectionError("Error: This room is already in use.");
      logUnexpectedError("Room " + room + " is full");
    });

    socket.io.on("reconnect_attempt", (tries: number) => {
      addBreadcrumb("info", "reconnect_attempt", { tries });
    });

    socket.io.on("reconnect", () => {
      addBreadcrumb("info", "reconnect");
    });

    socket.on("disconnect", (reason: string, details: any) => {
      if (
        reason === "io server disconnect" ||
        reason === "io client disconnect"
      ) {
        addBreadcrumb("info", "disconnect", { reason, details });
      } else {
        addBreadcrumb("error", "disconnect", { reason, details });
        logUnexpectedError(`Socket disconnected: ${reason}`);
      }
    });

    socket.on("connect", () => {
      addBreadcrumb("info", "connect");
      if (typeof socket.auth === "object") {
        socket.auth.firstSocketInstance = false;
      } else {
        logUnexpectedError("Socket auth not an object");
      }
      if (socket.id) {
        lastSocketIdRef.current = socket.id;
      }
    });

    // Usually errors when connecting or reconnecting
    socket.on("connect_error", (error: Error) => {
      addBreadcrumb("error", "connect_error", { error });
    });

    // our own backend error message
    socket.on("error", (errorCode) => {
      logUnexpectedError(errorCode);
      setConnectionError(
        `Internal error: ${errorCode}. Please try again later.`
      );
    });
  };

  const closeRemoteSocketConnection = () => {
    addBreadcrumb("info", "closeRemoteSocketConnection");
    if (socketRef.current) {
      socketRef.current.disconnect();
      socketRef.current = undefined;
    }
  };

  const endSession = () => {
    socketRef.current?.emit("end session", getClientOffset());
  };

  const sendMessage = (message: any) => {
    socketRef.current?.emit("message", message, getClientOffset());
  };

  return {
    setUpRemoteSocketConnection,
    closeRemoteSocketConnection,
    endSession,
    sendMessage,
    connectionError,
  };
};
