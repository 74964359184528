import { createSelector, createSlice } from "@reduxjs/toolkit";
import { getLocalPeerId } from "pages/Space/hooks/connection/usePeerWebRTCConnection";

export interface PeersSettings {
  [id: string]: {
    isMuted?: boolean;
    isVideoOff?: boolean;
    mediaError?: string;
  };
}

export interface SettingsState {
  clientCanControl: boolean;
  audioProviderOnly?: boolean;
  videoConferencing?: boolean;
  isMuted?: boolean;
  isVideoOff?: boolean;
  peerIdWithControl?: string;
  isVideoBlurred?: boolean;
  isNoiseSuppressionEnabled?: boolean;
  audioDevices: MediaDeviceInfo[];
  videoDevices: MediaDeviceInfo[];
  selectedAudioDevice?: MediaDeviceInfo;
  selectedVideoDevice?: MediaDeviceInfo;
  showResourceNameOnItemHover?: boolean;
  enableResourceNameViewer?: boolean;
  peersSettings: PeersSettings;
}

export interface RootState {
  settings: SettingsState;
}

export const settingsSlice = createSlice({
  name: "settings",
  initialState: {
    clientCanControl: true,
    peersSettings: {},
  } as SettingsState,
  reducers: {
    setClientCanControl: (state, value) => {
      state.clientCanControl = value.payload;
    },
    setAudioProviderOnly: (state, value) => {
      state.audioProviderOnly = value.payload;
    },
    setVideoConferencing: (state, value) => {
      state.videoConferencing = value.payload;
    },
    setIsMuted: (state, value) => {
      state.isMuted = value.payload;
    },
    setIsPeerMuted: (
      state,
      {
        payload: { peerId, isMuted },
      }: { payload: { peerId: string; isMuted: boolean } }
    ) => {
      state.peersSettings = {
        ...state.peersSettings,
        [peerId]: { ...state.peersSettings[peerId], isMuted },
      };
    },
    setIsPeerVideoOff: (
      state,
      {
        payload: { peerId, isVideoOff },
      }: { payload: { peerId: string; isVideoOff: boolean } }
    ) => {
      state.peersSettings = {
        ...state.peersSettings,
        [peerId]: { ...state.peersSettings[peerId], isVideoOff },
      };
    },
    setPeerMediaError: (
      state,
      {
        payload: { peerId, mediaError },
      }: { payload: { peerId: string; mediaError: string | undefined } }
    ) => {
      state.peersSettings = {
        ...state.peersSettings,
        [peerId]: { ...state.peersSettings[peerId], mediaError },
      };
    },
    setIsVideoOff: (state, { payload }: { payload: boolean | undefined }) => {
      state.isVideoOff = payload;
    },
    setPeerIdWithControl: (
      state,
      { payload }: { payload: string | undefined }
    ) => {
      state.peerIdWithControl = payload;
    },
    setIsVideoBlurred: (state, value) => {
      state.isVideoBlurred = value.payload;
    },
    setIsNoiseSuppressionEnabled: (
      state,
      { payload }: { payload: boolean | undefined }
    ) => {
      state.isNoiseSuppressionEnabled = payload;
    },
    setAudioDevices: (state, { payload }: { payload: MediaDeviceInfo[] }) => {
      state.audioDevices = payload;
    },
    setVideoDevices: (state, { payload }: { payload: MediaDeviceInfo[] }) => {
      state.videoDevices = payload;
    },
    setSelectedAudioDevice: (
      state,
      { payload }: { payload: MediaDeviceInfo | undefined }
    ) => {
      state.selectedAudioDevice = payload;
    },
    setSelectedVideoDevice: (
      state,
      { payload }: { payload: MediaDeviceInfo | undefined }
    ) => {
      state.selectedVideoDevice = payload;
    },
    setShowResourceNameOnItemHover: (state, value) => {
      state.showResourceNameOnItemHover = value.payload;
    },
    setEnableResourceNameViewer: (state, value) => {
      state.enableResourceNameViewer = value.payload;
    },
  },
});

export const {
  setClientCanControl,
  setAudioProviderOnly,
  setVideoConferencing,
  setIsMuted,
  setIsVideoOff,
  setPeerMediaError,
  setPeerIdWithControl,
  setIsPeerMuted,
  setIsPeerVideoOff,
  setIsVideoBlurred,
  setIsNoiseSuppressionEnabled,
  setAudioDevices,
  setVideoDevices,
  setSelectedAudioDevice,
  setSelectedVideoDevice,
  setShowResourceNameOnItemHover,
  setEnableResourceNameViewer,
} = settingsSlice.actions;

export const selectClientCanControl = (state: RootState) =>
  state.settings.clientCanControl;
export const selectAudioProviderOnly = (state: RootState) =>
  state.settings.audioProviderOnly;
export const selectVideoConferencing = (state: RootState) =>
  state.settings.videoConferencing;
export const selectIsMuted = (state: RootState) => state.settings.isMuted;
export const selectIsVideoOff = (state: RootState) => state.settings.isVideoOff;
export const selectPeerIdWithControl = (state: RootState) =>
  state.settings.peerIdWithControl;
export const selectPeerSettings = (state: RootState) =>
  state.settings.peersSettings;
export const selectIsVideoBlurred = (state: RootState) =>
  state.settings.isVideoBlurred;
export const selectIsNoiseSuppressionEnabled = (state: RootState) =>
  state.settings.isNoiseSuppressionEnabled;
export const selectAudioDevices = (state: RootState) =>
  state.settings.audioDevices;
export const selectVideoDevices = (state: RootState) =>
  state.settings.videoDevices;
export const selectSelectedAudioDevice = (state: RootState) =>
  state.settings.selectedAudioDevice;
export const selectSelectedVideoDevice = (state: RootState) =>
  state.settings.selectedVideoDevice;
export const selectShowResourceNameOnItemHover = (state: RootState) =>
  state.settings.showResourceNameOnItemHover;
export const selectEnableResourceNameViewer = (state: RootState) =>
  state.settings.enableResourceNameViewer;

export const selectCanControlCursor = (state: any) =>
  state.settings.peerIdWithControl === getLocalPeerId();

// combine general client control with cursor control to determine if the local user has control privileges
export const selectCanControl = (state: any) =>
  state.settings.clientCanControl && selectCanControlCursor(state);

export const selectIsPeerMuted = createSelector(
  selectPeerSettings,
  (_: any, peerId: string) => peerId,
  (peerSettings: PeersSettings, peerId: string) => peerSettings[peerId]?.isMuted
);

export const selectIsPeerVideoOff = createSelector(
  selectPeerSettings,
  (_: any, peerId: string) => peerId,
  (peerSettings: PeersSettings, peerId: string) =>
    peerSettings[peerId]?.isVideoOff
);

export const selectPeerMediaError = createSelector(
  selectPeerSettings,
  (_: any, peerId: string) => peerId,
  (peerSettings: PeersSettings, peerId: string) =>
    peerSettings[peerId]?.mediaError
);

export default settingsSlice.reducer;
