import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import mixpanel from "mixpanel-browser";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./index.css";

import App from "./App";
import AdminPage from "./pages/AdminPage/AdminPage";
import SentryProvider from "components/SentryProvider/SentryProvider";
import ReduxProvider from "./redux/ReduxProvider";
import TeleoMeeting from "pages/Space/TeleoMeeting";
import EpicMeeting from "pages/Space/EpicMeeting";
import Maintenance from "./Maintenance";

const main = () => {
  if (process.env.REACT_APP_MIXPANEL_TOKEN) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {
      api_host: process.env.REACT_APP_MIXPANEL_PROXY_SERVER,
      ignore_dnt: true,
    });
  }

  const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
  );
  root.render(
    // <React.StrictMode> // TODO: Look into re-enabling this, see https://stackoverflow.com/questions/61254372/my-react-component-is-rendering-twice-because-of-strict-mode
    <SentryProvider>
      <ReduxProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<App />} />
            <Route path="/space/:meetingUrl" element={<TeleoMeeting />} />
            <Route path="/session" element={<EpicMeeting />} />
            <Route path="/admin" element={<AdminPage />} />
            <Route path="/maintenance" element={<Maintenance />} />
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </ReduxProvider>
    </SentryProvider>
    // </React.StrictMode>,
  );
};

main();
